import { Action, ActionKeys } from "./authenticationActions";

export interface IAuthenticationState {
  token: string;
  refreshToken: string;
}

const initialAuthState: IAuthenticationState = {
  token: "",
  refreshToken: "",
};

export function authenticationReducer(
  state: IAuthenticationState = initialAuthState,
  action: Action
): IAuthenticationState {
  switch (action.type) {
    case ActionKeys.USER_AUTHENTICATED: {
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
      };
    }
    case ActionKeys.LOGOUT: {
      return {
        ...state,
        token: "",
        refreshToken: "",
      };
    }

    default:
      return state;
  }
}
