import { UserBalanceDto } from "../../types/dto/userProfile/UserBalanceDto";
import { UserProfileDto } from "../../types/dto/userProfile/UserProfileDto";
import { UserXPDto } from "../../types/dto/userProfile/UserXPDto";

export enum ActionKeys {
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  USER_LOADED = 'USER_LOADED',
  USER_LOADING_USER_BALANCE = 'USER_LOADING_USER_BALANCE',
  USER_LOADED_USER_BALANCE = 'USER_LOADED_USER_BALANCE',
  USER_LOADING_USER_XP = 'USER_LOADING_USER_XP',
  USER_LOADED_USER_XP = 'USER_LOADED_USER_XP',
  USER_LOADING_USER_PROFILE = 'USER_LOADING_USER_PROFILE',
  USER_LOADED_USER_PROFILE = 'USER_LOADED_USER_PROFILE',
}

export interface IUserLoadedAction {
  type: ActionKeys;
}

export interface IUserLoggedOutAction {
  type: ActionKeys;
}

export interface ILoadingUserBalanceAction {
  type: ActionKeys;
}

export interface ILoadedUserBalanceAction {
  type: ActionKeys;
  balance: UserBalanceDto;
}

export interface ILoadingUserXPAction {
  type: ActionKeys;
}

export interface ILoadedUserXPAction {
  type: ActionKeys;
  balance: UserXPDto;
}

export interface ILoadingUserProfileAction {
  type: ActionKeys;
}

export interface ILoadedUserProfileAction {
  type: ActionKeys;
  profile: UserProfileDto;
}


export type Action = IUserLoadedAction | IUserLoggedOutAction | ILoadingUserBalanceAction | ILoadedUserBalanceAction | ILoadingUserProfileAction | ILoadedUserProfileAction;
