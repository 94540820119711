export enum ActionKeys {
  INCREMENT_NOTIFICATION_NUMBER = 'INCREMENT_NOTIFICATION_NUMBER',
  DECREMENT_NOTIFICATION_NUMBER = 'DECREMENT_NOTIFICATION_NUMBER',
}

export interface INotificationAction {
  type: ActionKeys;
}

export type Action = INotificationAction;
