import React, { useEffect, useState } from 'react';
import { getLeagueEntryAnswers } from '../../../api/ESports/leagueEntryService';
import { CouponFixtureSummaryDto } from '../../../types/dto/couponFixture/CouponFixtureSummaryDto';
import LeagueEntryFixtureAnswersDto from '../../../types/dto/leagueEntry/LeagueEntryFixtureAnswersDto';
import StatusEnum from '../../../types/enums/StatusEnum';
import TeamVs from '../../../components/common/team/TeamVs';

interface ILeagueEntryAnswersProps {
    leagueEntryId: number;
    fixtures: CouponFixtureSummaryDto[];
    closeMyBet: () => void;
}

enum FixtureFilter {
    None,
    Prev,
    Current,
    Next
}

const LeagueEntryAnswers: React.FC<ILeagueEntryAnswersProps> = (props: ILeagueEntryAnswersProps) => {

    const { leagueEntryId, fixtures, closeMyBet } = props;

    var prevFixtures = fixtures.filter(x => x.statusId === StatusEnum.Finished);
    var prevFixture = prevFixtures.length > 0 ? prevFixtures.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime())[prevFixtures.length - 1] : undefined;
    var currentFixtures = fixtures.filter(x => x.statusId === StatusEnum.Running);
    var currentFixture = currentFixtures.length > 0 ? currentFixtures.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime())[0] : undefined;
    var nextFixtures = fixtures.filter(x => x.statusId === StatusEnum.Open);
    var nextFixture = nextFixtures.length > 0 ? nextFixtures.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime())[nextFixtures.length - 1] : undefined;

    const [loading, setLoading] = useState<boolean>(true);
    const [leagueEntryAnswers, setLeagueEntryAnswers] = useState<LeagueEntryFixtureAnswersDto[]>([]);
    const [selectedFixture, setSelectedFixture] = useState<number|undefined>(currentFixture?.fixtureId ? currentFixture?.fixtureId : nextFixture?.fixtureId);

    useEffect(() => {
        let cancelled = false;

        setLoading(true);

        getLeagueEntryAnswers(leagueEntryId)
            .then((r) => {
                if (cancelled) return;
                setLeagueEntryAnswers(r);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });

        return () => {
            cancelled = true;
        }
    }, [leagueEntryId]);

    const renderAnswers = (f: LeagueEntryFixtureAnswersDto[]) => {
        return (
            <div>
                <div className="row">
                    {f.map((a) => (
                        <div className={`col-12 ${a.answerValue == a.resultValue ? "correct" : "incorrect"}`}>
                            <h3>{a.question}</h3>
                            <div className="row">
                                <div className="col-5">
                                    <h4>Your Answer</h4>
                                    <div className="name">
                                        {a.questionTypeID === 1 && <div className="number">{a.participantName ? a.participantName : '-'}</div>}
                                        {a.questionTypeID === 2 && <div className="number">{a.answerValue > 0 ? a.answerValue : "-"}</div>}
                                        {a.questionTypeID === 4 && <div className="number">{a.team ? a.team : '-'}</div>}
                                    </div>
                                </div>
                                <div className="col-5">
                                    <h4>Actual Answer</h4>
                                    <div className="name">
                                        {a.questionTypeID === 1 && <div className="number">{a.resultParticipantName ? a.resultParticipantName : '-'}</div>}
                                        {a.questionTypeID === 2 && <div className="number">{a.resultValue > 0 ? a.resultValue : "-"}</div>}
                                        {a.questionTypeID === 4 && <div className="number">{a.resultTeam ? a.resultTeam : '-'}</div>}
                                    </div>
                                </div>
                                <div className="col-2">
                                    <h4>Points</h4>
                                    <div className="name">
                                        {a.points} / {a.availablePoints}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

  return (
    <div className="col-lg-6 sections">
        <a href="#" onClick={closeMyBet}>{">"}</a>
        {loading && <div>Loading ...</div>}
        {!loading && (fixtures.map((f) => (
            <div key={f.fixtureId} onClick={() => setSelectedFixture(f.fixtureId)}>
                {<TeamVs 
                    homeTeamName={f.homeTeamName} 
                    homeTeamImageUrl={f.homeTeamImageUrl} 
                    awayTeamName={f.awayTeamName} 
                    awayTeamImageUrl={f.awayTeamImageUrl} 
                />}
                <span className="badge badge-pill badge-info">{leagueEntryAnswers.filter((x) => x.fixtureId == f.fixtureId).reduce((partialSum, a) => partialSum + a.availablePoints, 0)}pts</span>
                {selectedFixture == f.fixtureId && renderAnswers(leagueEntryAnswers.filter((x) => x.fixtureId === selectedFixture))}
            </div>
        )))}
    </div>
  )
};

export default LeagueEntryAnswers;
