import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCurrentUserLeagues } from "../../../api/ESports/leagueEntryService";
import LeagueEntrySummaryDto from "../../../types/dto/leagueEntry/LeagueEntrySummaryDto";
import { ResponseSetDto } from "../../../types/dto/ResponseSetDto";
import LeagueEntryStatusEnum from "../../../types/enums/LeagueEntryStatus";
import LeagueEntryTimeFilterEnum from "../../../types/enums/LeagueEntryTimeFilterEnum";
import { formatCurrency } from "../../../utils/NumberUtil";
import Countdown from "react-countdown";
import CountdownRenderer from "../CountdownRenderer";
import LoadingSpinner from "../LoadingSpinner";

interface ISearchLeaguesParams {
  status: LeagueEntryStatusEnum;
  timeFilter: LeagueEntryTimeFilterEnum;
  page: number;
  limit: number;
}

const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const CurrentUserLeagues: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<
    ResponseSetDto<LeagueEntrySummaryDto>
  >({
    totalCount: 0,
    count: 0,
    model: [],
  });
  const [searchResultsGrouped, setSearchResultsGrouped] = useState<any>({});
  const [searchParams, setSearchParams] = useState<ISearchLeaguesParams>({
    status: LeagueEntryStatusEnum.Open,
    timeFilter: LeagueEntryTimeFilterEnum.Today,
    page: 0,
    limit: 100,
  });

  const loadLeagues = (cancelled = false) => {
    setLoading(true);

    var from = new Date();
    var to = new Date();

    if (searchParams.status === LeagueEntryStatusEnum.Open) {
      if (searchParams.timeFilter === LeagueEntryTimeFilterEnum.Future) {
        to = "" as unknown as Date;
        from.setDate(from.getDate() + 1);
      }
    } else if (searchParams.status === LeagueEntryStatusEnum.Settled) {
      from = "" as unknown as Date;
    }

    getCurrentUserLeagues(
      searchParams.status,
      from,
      to,
      0,
      searchParams.page,
      searchParams.limit
    )
      .then((result) => {
        if (cancelled) return;

        setSearchResultsGrouped(groupBy(result.model, "leagueId"));
        setSearchResults(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    var cancelled = false;

    loadLeagues(cancelled);

    return () => {
      cancelled = true;
    };
  }, [
    searchParams.limit,
    searchParams.page,
    searchParams.status,
    searchParams.timeFilter,
  ]);

  var resultKeys = Object.keys(searchResultsGrouped);

  return (
    <React.Fragment>
      <div className="dashboard_leagues">
        <div className="leagues_header">
          <h2>
            My Leagues <span>{resultKeys.length}</span>
          </h2>
          <div className="filters">
            <div className="btn_group">
              <div>
                <input
                  type="radio"
                  checked={searchParams.status === LeagueEntryStatusEnum.Open}
                  id="open_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      status: LeagueEntryStatusEnum.Open,
                    })
                  }
                />
                <label htmlFor="open_bet-type">Open</label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.status === LeagueEntryStatusEnum.Settled
                  }
                  id="settled_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      status: LeagueEntryStatusEnum.Settled,
                    })
                  }
                />
                <label htmlFor="settled_bet-type">Settled</label>
              </div>
            </div>
            {searchParams.status === LeagueEntryStatusEnum.Open && (
              <div className="btn_group">
                <div>
                  <input
                    type="radio"
                    checked={
                      searchParams.timeFilter ===
                      LeagueEntryTimeFilterEnum.Today
                    }
                    id="today_league-date"
                    name="league-date"
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        timeFilter: LeagueEntryTimeFilterEnum.Today,
                      })
                    }
                  />
                  <label htmlFor="today_league-date">Today</label>
                </div>
                <div>
                  <input
                    type="radio"
                    checked={
                      searchParams.timeFilter ===
                      LeagueEntryTimeFilterEnum.Future
                    }
                    id="yest_league-date"
                    name="league-date"
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        timeFilter: LeagueEntryTimeFilterEnum.Future,
                      })
                    }
                  />
                  <label htmlFor="yest_league-date">Future</label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="leagues_list">
          {loading && (
            <div className="no-leagues">
              <LoadingSpinner />
            </div>
          )}
          {!loading && resultKeys && resultKeys.length == 0 && (
            <div className="no-leagues">
              <h3>You don't currently have any upcoming leagues</h3>
              <Link to={`/upcoming-leagues`} className="btn btn-primary">
                Enter League
              </Link>
            </div>
          )}
          {!loading &&
            searchResults.model &&
            searchResults.model.length > 0 &&
            searchResults.model.map((l) => {
              // var l = searchResultsGrouped[k][0];
              var isInFuture = new Date(l?.entryCloseDate) > new Date();
              return (
                <div className="league" key={l.leagueId}>
                  <span className="league_name">
                    <span className="league_icon">
                      <img src={l.imageURL} alt="Premier League" />
                    </span>
                    <div>
                      <h4>{l.sportName}</h4>
                      <h3>{l.name}</h3>
                    </div>
                  </span>
                  <div className="league_details">
                    <span className="value">
                      {formatCurrency(Number(l.entryPrice))} league
                    </span>
                    <span className="entries">{`${l.noOfEntries} Entries `}</span>
                  </div>
                  {!isInFuture && (
                    <span className="league_status">
                      <span className="position" key={l.leagueEntryId}>
                        {Number(l.position)}
                      </span>
                    </span>
                  )}
                  {isInFuture && (
                    <span className="league_status">
                      <span>Starts in</span>
                      <Countdown
                        date={l?.entryCloseDate}
                        renderer={CountdownRenderer}
                      />
                    </span>
                  )}

                  {/* <span className="league_details">
                                        <span className="value">{formatCurrency(searchResultsGrouped[k].reduce((partialSum: any, a: any) => partialSum + a.prize, 0))}</span>
                                    </span> */}
                  <span className="league_actions">
                    {l.prize && (
                      <Link to="/manage-account/settled-bets" className="">
                        <span className="sr-only">View prizes</span>
                        <i className="fal fa-pound-sign"></i>
                      </Link>
                    )}
                    {isInFuture && (
                      <Link
                        to={`/enter-coupon/${l.couponId}/${l.leagueEntryId}`}
                      >
                        <span className="sr-only">Edit league</span>
                        <i className="fal fa-pencil"></i>
                      </Link>
                    )}
                    <Link to={`/league/${l.leagueId}`} className="link">
                      <span className="sr-only">View league</span>
                      <i className="fal fa-chevron-right"></i>
                    </Link>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CurrentUserLeagues;
