import React from "react";
import ComingSoonOverlay from "../common/ComingSoonOverlay";

const SuperLeagueWidget: React.FC<{}> = () => {
  return (
    <div className="super_league aside-panel">
      <div className="header">
        <div className="hexagon">
          <i className="fas fa-trophy"></i>
        </div>
        <h3>Super League</h3>
        <a href="">
          <span className="sr-only">Need help?</span>
          <i className="fal fa-info-circle"></i>
        </a>
      </div>
      <div className="coming-soon">
        <div className="body">
          <ul className="super_league_table">
            <li className="league_row">
              <span className="position">1st</span>
              <span className="prize">£250.00</span>
              <span className="username">cjohn25</span>
              <span className="username">
                45 <small>pts</small>
              </span>
            </li>
            <li className="league_row">
              <span className="position">1st</span>
              <span className="prize">£250.00</span>
              <span className="username">cjohn25</span>
              <span className="username">
                45 <small>pts</small>
              </span>
            </li>
            <li className="league_row">
              <span className="position">1st</span>
              <span className="prize">£250.00</span>
              <span className="username">cjohn25</span>
              <span className="username">
                45 <small>pts</small>
              </span>
            </li>
            <li className="league_row">
              <span className="position">1st</span>
              <span className="prize">£250.00</span>
              <span className="username">cjohn25</span>
              <span className="username">
                45 <small>pts</small>
              </span>
            </li>
            <li className="league_row my_position">
              <span className="position">1st</span>
              <span className="prize">£250.00</span>
              <span className="username">cjohn25</span>
              <span className="username">
                45 <small>pts</small>
              </span>
            </li>
          </ul>
        </div>
        <div className="footer">
          <a href="" className="btn btn-primary btn-sm">
            Full leaderboard
          </a>
          <a href="" className="text-link">
            How it works?
          </a>
        </div>
      </div>
    </div>
  );
};

export default SuperLeagueWidget;
