import * as React from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

type ToastProps = {
  title: string;
  message: string;
};

const toastOptions: ToastOptions = {
  autoClose: 5000,
  position: 'top-right',
  className: 'foo-bar',
};

export function createInfoToast(message: string): React.ReactText {
  return toast.info(<Toast title="Information:" message={message} />, toastOptions);
}

export function createSuccessToast(message: string): React.ReactText {
  return toast.success(<Toast title="Success:" message={message} />, toastOptions);
}

export function createWarningToast(message: string): React.ReactText {
  return toast.warning(<Toast title="Warning:" message={message} />, toastOptions);
}

export function createErrorToast(message: string): React.ReactText {
  return toast.error(<Toast title="Error:" message={message} />, toastOptions);
}

export function createValidationErrorToast(): React.ReactText {
  return createErrorToast('Please correct any errors and try again.');
}

export function createGenericErrorToast(): React.ReactText {
  return createErrorToast('An error has occurred, please try again.');
}

export function createSavedToast(entity: string): React.ReactText {
  return toast.success(<Toast title="Saved:" message={`The ${entity}, has been successfully saved.`} />, toastOptions);
}

export function createUdpatedToast(entity: string): React.ReactText {
  return toast.success(
    <Toast title="Updated:" message={`The ${entity}, has been successfully updated.`} />,
    toastOptions
  );
}

export function createDeletedToast(entity: string): React.ReactText {
  return toast.success(
    <Toast title="Deleted:" message={`The ${entity}, has been successfully deleted.`} />,
    toastOptions
  );
}

export function dismiss(toastId: React.ReactText): void {
  toast.dismiss(toastId);
}

export function dismissAll(): void {
  toast.dismiss();
}

export const ReactToastifyContainer: React.FC = () => {
  return <ToastContainer limit={3} />;
};

export const Toast = ({ title, message }: ToastProps) => {
  return <React.Fragment>{message}</React.Fragment>;
};
