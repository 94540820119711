import FixtureStatsDto from '../../types/dto/fixture/FixtureStatsDto';
import { genericProtectedGet } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/Fixture/`;

export function getStatsForFixtureCoupon(
  fixtureId: number,
  couponId: number
): Promise<FixtureStatsDto> {
  const url = new URL(`${baseApiUrl}${fixtureId}/Coupon/${couponId}/Stats`);
  return genericProtectedGet<FixtureStatsDto>(url.toString());
}
