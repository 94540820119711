import React from "react";
import { Form } from "react-bootstrap";

const OpenBets: React.FC<{}> = () => {
  return (
    <main className="open-bet">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <div className="title_container">
        <h2>Open Bets</h2>
        <p>These are your current in play bets.</p>
        <a
          href=""
          className="toggle_filters btn btn-md btn-outline-light"
          id="toggle_open_filters"
        >
          <span className="sr-only">Toggle Filters</span>
          <i className="fas fa-filter"></i>
        </a>
      </div>

      <div className="table_filters d-none" id="account_open_filters">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("Bets form submitted");
          }}
        >
          <div className="form-group">
            <label>Date from:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>Date to:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>League ID:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="footer_btns">
            <a href="" className="btn btn-sm btn-primary">
              Search
            </a>
            <a className="text-link">Clear</a>
          </div>
        </Form>
      </div>
      <div className="empty_state">
        <i className="fal fa-list fa-2x"></i>
        <h3>You do not currently have any open bets</h3>
        <a href="" className="btn btn-md btn-primary">
          Enter a league
        </a>
      </div>

      <div className="table-container table-responsive-sm">
        <table className="table table-dark table-sm table-hover open-bets">
          <tbody>
            <thead>
              <tr>
                <th>League Name</th>
                <th>Stake</th>
                <th>Current Position</th>
                <th>Entry ID</th>
                <th>League ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Example league name</td>
                <td>£2.50</td>
                <td>1st</td>
                <td>11442</td>
                <td>23232</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </tbody>
        </table>
      </div>
      <div className="footer_btns">
        <a href="/content/account.php" className="btn btn-md btn-outline-light">
          Settled Bets
        </a>
      </div>
    </main>
  );
};

export default OpenBets;
