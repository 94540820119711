import React, { useEffect, useState } from "react";
import { CouponSummaryDto } from "../../types/dto/coupon/CouponSummaryDto";
import { getFeaturedCoupon } from "../../api/ESports/couponService";
import Countdown, { CountdownRendererFn } from "react-countdown";
import CountdownRenderer from "../common/CountdownRenderer";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const FeaturedCoupon: React.FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<CouponSummaryDto>();

  useEffect(() => {
    getFeaturedCoupon().then((featuredCoupon) => {
      setCoupon(featuredCoupon);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      {!loading && coupon && (
        <div
          className="featured-coupon"
          style={{
            backgroundImage: 'url("/images/CSGO-Advert-BG.png")', // TODO: Get back coupon.bgImageUrl in API response
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <div className="d-flex justify-content-between align-items-start">
            <div className="coupon-titles">
              <h4>{coupon.productName}</h4>
              <h3>{coupon.couponName}</h3>
            </div>
            <div>
              <img src={coupon.imageUrl} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex">
              <div className="featured-coupon-detail" style={{ minWidth: 200 }}>
                <span className="advert-title">Starts In</span>
                <span className="advert-value">
                  <Countdown
                    date={coupon.entryCloseDate}
                    renderer={CountdownRenderer}
                  />
                </span>
              </div>
              <div className="featured-coupon-detail">
                <span className="advert-title">League</span>
                {/* TODO: Get coupon price back in API response */}
                <span className="advert-value">£5.00</span>
              </div>
            </div>
            <div>
              <Link
                to={`/enter-coupon/${coupon.couponId}`}
                className="btn btn-primary"
              >
                Enter League
              </Link>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FeaturedCoupon;
