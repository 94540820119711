import React from 'react';

const SidebarPayments: React.FC<{}> = () => {
  

  return (
    <React.Fragment>
      <div className="balance_container">
          <div className="balance"><span>Balance</span><span className="amount">£0.00</span></div>
          <div className="promo"><span>Free bets</span><span className="amount">£0.00</span></div>
      </div>
      <img src="/images/card-logo-1.png" className="img-responsive" />
      <div className="sidebar_block contact_block">
          <div className="title clearfix">
              <h4> Customer Support</h4>
          </div>
          <div className="block-content">
              <ul className="list-unstyled">
                  <li>
                      <i className="fal fa-envelope"></i>                                    
                      <span>
                          <h5>Email us on</h5>
                          <p>
                              <a className="email" href="mailto:support@pool250.com">support@pool250.com</a><br/>
                              <small>We will aim to respond within 12 hours</small>
                          </p>
                      </span>
                  </li>
              </ul>
          </div>
      </div>
      <div className="secured-by-block">
          <i className="fal fa-lock fa-2x pull-left"></i> 
          <span className="pull-left">Secured by</span>
          <img src="/images/verisign_logo-1.jpg" className="img-responsive"/>
      </div>
    </React.Fragment>
  );
};

export default SidebarPayments;
