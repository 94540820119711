import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isAuthenticatedSelector } from "../store/authentication/authenticationSelector";

export const PageNotFound: React.FC<{}> = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <div id="page-not-found">
      <span>
        <i className="fal fa-exclamation-triangle" />
      </span>
      <h1>Oops! Page Not Found </h1>
      <Link to="/">
        <i className="far fa-chevron-left" />
        {isAuthenticated ? ` Back to Dashboard` : ` Back to login`}
      </Link>
    </div>
  );
};
