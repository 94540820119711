import React from 'react';

const ChatFriendsWidget: React.FC<{}> = () => {
  return (
    <div className="friends">
        <div className="header">
            <label className="sr-only">League Chats</label>
            <input type="text" placeholder="Type to search" />
        </div>
        <div className="body">
            <ul className="friend_list">
                <li className="friend_item">
                    <span className="userProfile">RM</span>
                    <span className="userDetails"><span className="username">rmounty</span> <span className="name">Richard Mounty</span></span>
                    <a href="" className=""><span className="sr-only"></span><i className=""></i></a>        
                    <a href="" className=""><span className="sr-only"></span><i className=""></i></a>    
                    <a href="" className=""><span className="sr-only"></span><i className=""></i></a>    
                </li>
            </ul>
        </div>
    </div>
  )
};

export default ChatFriendsWidget;
