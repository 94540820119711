import { UserBalanceDto } from '../../types/dto/userProfile/UserBalanceDto';
import { UserProfileDto } from '../../types/dto/userProfile/UserProfileDto';
import { UserXPDto } from '../../types/dto/userProfile/UserXPDto';
import { genericProtectedGet } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/UserProfile/`;

export function getUserProfile(): Promise<UserProfileDto> {
  return genericProtectedGet<UserProfileDto>(`${baseApiUrl}`);
}

export function getUserBalance(): Promise<UserBalanceDto> {
  return genericProtectedGet<UserBalanceDto>(`${baseApiUrl}Balance`);
}

export function getUserXP(): Promise<UserXPDto> {
  return genericProtectedGet<UserXPDto>(`${baseApiUrl}Xp`);
}

