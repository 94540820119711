import React from 'react';
import { useSelector } from 'react-redux';
import {  currentUserBalanceSelector, currentUserPromoBalanceSelector, currentXPSelector, isLoadingBalanceSelector, isLoadingXPSelector } from '../../../store/currentUser/currentUserSelector';
import { formatCurrency, formatXP } from '../../../utils/NumberUtil';

const Stats: React.FC<{}> = () => {

  const loading = useSelector(isLoadingBalanceSelector);
  const balance = useSelector(currentUserBalanceSelector);
  const promoBalance = useSelector(currentUserPromoBalanceSelector);
  const userXP = useSelector(currentXPSelector);

  return (
    <React.Fragment>
      {loading && (<div></div>)}
      {!loading && (
        <div className="dashboard_stats">
            <div className="stat balance">
                <span className="label">My Balance</span>
                <span className="value">{balance ? formatCurrency(Number(balance)) : '0.00'}</span>
            </div>
            <div className="stat free">
                <span className="label">Free bets</span>
                <span className="value">{promoBalance ? formatCurrency(Number(promoBalance)) : '0.00'}</span>
            </div>
            <div className="stat leagues">
                <span className="label">Active Leagues</span>
                <span className="value">0</span>
            </div>
            <div className="stat xp">
                <span className="label">XP Points</span>
                <span className="value">{formatXP(userXP.currentXP ?? 0, "0")}</span>
            </div>
        </div>
      )}
    </React.Fragment>
  )
};

export default Stats;
