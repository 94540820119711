
import React, { useState } from 'react';
import { isCurrentUrl } from '../../utils/UrlUtil';
import SideMenu, { ISideMenu } from '../common/menu/SideMenu';
  
const Aside: React.FC = () => {

  const mainSidebarMenu = [
    {label: "Dashboard", url: "/dashboard", active: isCurrentUrl("/dashboard")},
    {label: "Upcoming Leagues", url: "/upcoming-leagues", active: isCurrentUrl("/upcoming-leagues")},
    {label: "My Bets", url: "/my-bets", active: isCurrentUrl("/my-bets")},
    {label: "Leaderboards"},
    {label: "Manage Account", url: "/manage-account/deposit", active: isCurrentUrl("/manage-account")}
  ] as ISideMenu[];
  
  const sportsSidebarMenu = [
    {label: "CSGO", url: "/sports/3", active: isCurrentUrl("/sports/3"), fav: true},
    {label: "League of Legends"},
    {label: "DOTA 2"},
    {label: "Call of Duty"},
    {label: "FIFA23"},
  ] as ISideMenu[];
  
  const esportsLeaguesSidebarMenu = [
    {label: "Policies", url: "/policies", active: isCurrentUrl("/policies")},
  ] as ISideMenu[];

  return (
    <aside>
      <SideMenu title="My Account" links={mainSidebarMenu} />
      <SideMenu title="E-Sports" links={sportsSidebarMenu} />
      {/* <SideMenu title="ESports Leagues" links={esportsLeaguesSidebarMenu} /> */}
      <footer>
        <p>&copy; ESports Leagues</p>
      </footer>
    </aside>
  );
};

export default Aside;
  