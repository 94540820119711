import AuthenticationResultDto from '../../types/dto/authentication/AuthenticationResultDto';
import ForgottenPasswordDto from '../../types/dto/authentication/ForgottenPasswordDto';
import LoginDto from '../../types/dto/authentication/LoginDto';
import { TransactionResultDto } from '../../types/dto/TransactionResultDto';
import { genericPost } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/Auth/`;

export function authenticateUser(request: LoginDto): Promise<TransactionResultDto<AuthenticationResultDto>> {
  return genericPost<AuthenticationResultDto, LoginDto>(`${baseApiUrl}Login`, request);
}

export function forgottenPassword(request: ForgottenPasswordDto): Promise<TransactionResultDto<ForgottenPasswordDto>> {
  return genericPost<ForgottenPasswordDto, ForgottenPasswordDto>(`${baseApiUrl}ForgottenPassword`, request);
}

