import { Action, ActionKeys } from './notificationActions';

export interface INotificationState {
  numberOfNotifications: number;
}

export const initialNotificationState: INotificationState = {
  numberOfNotifications: 0,
};

export function notificationReducer(
  state: INotificationState = initialNotificationState,
  action: Action
): INotificationState {
  switch (action.type) {
    case ActionKeys.INCREMENT_NOTIFICATION_NUMBER:
      return { numberOfNotifications: state.numberOfNotifications + 1 };
    case ActionKeys.DECREMENT_NOTIFICATION_NUMBER:
      return { numberOfNotifications: state.numberOfNotifications - 1 };
    default:
      return state;
  }
}
