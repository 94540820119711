import React from "react";
import { Form } from "react-bootstrap";

const SettledBets: React.FC<{}> = () => {
  return (
    <main className="settled-bets">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <div className="title_container">
        <h2>Settled Bets</h2>
        <p>Use the form below to search through your bet history.</p>
        <a
          href=""
          className="toggle_filters btn btn-md btn-outline-light"
          id="toggle_settled_filters"
        >
          <span className="sr-only">Toggle Filters</span>
          <i className="fas fa-filter"></i>
        </a>
      </div>

      <div className="table_filters d-none" id="account_settled_filters">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("Settled bets form submitted");
          }}
        >
          <div className="form-group">
            <label>Date from:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>Date to:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>League ID:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="footer_btns">
            <a href="" className="btn btn-sm btn-primary">
              Search
            </a>
            <a className="text-link">Clear</a>
          </div>
        </Form>
      </div>
      <div className="table-container table-responsive-sm" id="account_table">
        <table className="table table-dark table-sm table-hover  open-bets">
          <tbody>
            <thead>
              <tr>
                <th>League ID</th>
                <th>Players</th>
                <th>Name</th>
                <th>Date</th>
                <th>Bet</th>
                <th>Returns</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>22111</td>
                <td>18</td>
                <td>Exampleusername9</td>
                <td>01st August 2020</td>
                <td>£20.00</td>
                <td>£0.00</td>
                <td className="action">
                  <a href="">
                    <span className="sr-only"></span>
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </tbody>
        </table>
      </div>
      <div className="footer_btns">
        <a href="/content/account.php" className="btn btn-md btn-outline-light">
          Open Bets
        </a>
      </div>
    </main>
  );
};

export default SettledBets;
