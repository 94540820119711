import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCoupons } from "../../api/ESports/couponService";
import { CouponSummaryDto } from "../../types/dto/coupon/CouponSummaryDto";
import { ResponseSetDto } from "../../types/dto/ResponseSetDto";
import CouponTimeFilterEnum from "../../types/enums/CouponTimeFilterEnum";
import { formatCurrency } from "../../utils/NumberUtil";

interface ISearchCouponParams {
  timeFilter: CouponTimeFilterEnum;
  page: number;
  limit: number;
}

const UpcomingCouponWidget: React.FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<
    ResponseSetDto<CouponSummaryDto>
  >({
    totalCount: 0,
    count: 0,
    model: [],
  });
  const [searchParams, setSearchParams] = useState<ISearchCouponParams>({
    timeFilter: CouponTimeFilterEnum.Today,
    page: 0,
    limit: 100,
  });

  const loadCoupons = (cancelled = false) => {
    setLoading(true);

    var from = new Date();
    var to = new Date();

    if (searchParams.timeFilter === CouponTimeFilterEnum.Future) {
      to = "" as unknown as Date;
    }

    getCoupons(
      null as unknown as string,
      from,
      to,
      searchParams.page,
      searchParams.limit
    )
      .then((result) => {
        if (cancelled) return;

        setSearchResults(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    var cancelled = false;

    loadCoupons(cancelled);

    return () => {
      cancelled = true;
    };
  }, [searchParams.limit, searchParams.page, searchParams.timeFilter]);

  return (
    <div className="aside-panel side-bets">
      <div className="header">
        <div className="icon">
          <i className="far fa-coins"></i>
        </div>
        <h3>Side bets</h3>
        {/* <div className="btn_group">
          <div>
            <input
              type="radio"
              checked={searchParams.timeFilter === CouponTimeFilterEnum.Today}
              id="today_coupon-date"
              name="coupon-date"
              onClick={() =>
                setSearchParams({
                  ...searchParams,
                  timeFilter: CouponTimeFilterEnum.Today,
                })
              }
            />
            <label htmlFor="today_coupon-date">Today</label>
          </div>
          <div>
            <input
              type="radio"
              checked={searchParams.timeFilter === CouponTimeFilterEnum.Future}
              id="yest_coupon-date"
              name="coupon-date"
              onClick={() =>
                setSearchParams({
                  ...searchParams,
                  timeFilter: CouponTimeFilterEnum.Future,
                })
              }
            />
            <label htmlFor="yest_coupon-date">Future</label>
          </div>
        </div> */}
        <a href="" className="number-circle">
          {searchResults.totalCount}
        </a>
      </div>
      <div className="coming-soon">
        <div className="body">
          <ul className="bets">
            {!loading &&
              searchResults &&
              searchResults.model &&
              searchResults.model.map((x) => (
                <li className="bet" key={x.couponId}>
                  <div className="icon">
                    <span>
                      <img src={x.imageUrl} alt="" />
                    </span>
                  </div>
                  <div className="description">
                    <h3>{x.couponName}</h3>
                    {/* <h4>10 vs 15</h4> */}
                  </div>
                  <div className="amount">
                    <Link
                      to={`/enter-coupon/${x.couponId}`}
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </Link>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="footer">
          <a href="" className="text-link">
            How it works?
          </a>
        </div>
      </div>
    </div>
  );
};

export default UpcomingCouponWidget;
