import React, { useEffect, useState } from "react";
import { formatXP } from "../../../utils/NumberUtil";
import { useSelector } from "react-redux";
import {
  currentXPSelector,
  isLoadingXPSelector,
} from "../../../store/currentUser/currentUserSelector";

const CurrentUserXP: React.FC = () => {
  const loading = useSelector(isLoadingXPSelector);
  const userXP = useSelector(currentXPSelector);
  const xpToNextLevel = (userXP.nextLevelXP ?? 0) - (userXP.currentXP ?? 0);
  const currentLevelXP =
    (userXP.nextLevelXP ?? 0) - (userXP.currentLevelXP ?? 0);
  const currentLevelProgress = currentLevelXP - xpToNextLevel;
  const percentComplete =
    currentLevelXP > 0 ? (currentLevelProgress / currentLevelXP) * 100 : 0;

  return (
    <div className="user_level">
      <div className="description">
        <p>Current level </p>
        <p>
          <span className="xp-count">
            {!loading && formatXP(xpToNextLevel)} XP
          </span>{" "}
          to next level
        </p>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percentComplete}%` }}
            aria-valuenow={percentComplete}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </div>
      <div className="level hexagon">{!loading && userXP.currentLevelName}</div>
    </div>
  );
};

export default CurrentUserXP;
