import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCoupons } from "../../../api/ESports/couponService";
import { CouponSummaryDto } from "../../../types/dto/coupon/CouponSummaryDto";
import { ResponseSetDto } from "../../../types/dto/ResponseSetDto";
import CouponTimeFilterEnum from "../../../types/enums/CouponTimeFilterEnum";
import LeagueEntryStatusEnum from "../../../types/enums/LeagueEntryStatus";
import LoadingSpinner from "../LoadingSpinner";

interface ISearchCouponParams {
  timeFilter: CouponTimeFilterEnum;
  statusFilter: LeagueEntryStatusEnum;
  page: number;
  limit: number;
}

// TODO: Update typing for CouponSummaryDto and remove this interface when API
// is returning all required data
interface CouponSummaryDtoWIP extends CouponSummaryDto {
  position: number;
}

const UpcomingLeagues: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<
    ResponseSetDto<CouponSummaryDtoWIP>
  >({
    totalCount: 1,
    count: 1,
    model: [],
  });
  const [searchParams, setSearchParams] = useState<ISearchCouponParams>({
    timeFilter: CouponTimeFilterEnum.Today,
    statusFilter: LeagueEntryStatusEnum.Open,
    page: 0,
    limit: 100,
  });

  const loadCoupons = (cancelled = false) => {
    setLoading(true);

    var from = new Date();
    var to = new Date();

    if (searchParams.timeFilter === CouponTimeFilterEnum.Future) {
      to = "" as unknown as Date;
    }

    getCoupons(
      null as unknown as string,
      from,
      to,
      searchParams.page,
      searchParams.limit
    )
      .then((result) => {
        if (cancelled) return;

        setSearchResults({
          ...result,
          model: result.model.map((m) => ({ ...m, position: 13 })),
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    var cancelled = false;

    loadCoupons(cancelled);

    return () => {
      cancelled = true;
    };
  }, [searchParams.limit, searchParams.page, searchParams.timeFilter]);

  return (
    <React.Fragment>
      <div className="dashboard_leagues">
        <div className="leagues_header">
          <div className="filters">
            <div className="btn_group">
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.statusFilter === LeagueEntryStatusEnum.Open
                  }
                  id="open_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      statusFilter: LeagueEntryStatusEnum.Open,
                    })
                  }
                />
                <label htmlFor="open_bet-type">Open</label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.statusFilter === LeagueEntryStatusEnum.Settled
                  }
                  id="settled_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      statusFilter: LeagueEntryStatusEnum.Settled,
                    })
                  }
                />
                <label htmlFor="settled_bet-type">Settled</label>
              </div>
            </div>
            <div className="btn_group">
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.timeFilter === CouponTimeFilterEnum.Today
                  }
                  id="today_coupon-date"
                  name="coupon-date"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      timeFilter: CouponTimeFilterEnum.Today,
                    })
                  }
                />
                <label htmlFor="today_coupon-date">Today</label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.timeFilter === CouponTimeFilterEnum.Future
                  }
                  id="yest_coupon-date"
                  name="coupon-date"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      timeFilter: CouponTimeFilterEnum.Future,
                    })
                  }
                />
                <label htmlFor="yest_coupon-date">Tomorrow</label>
              </div>
            </div>
          </div>
        </div>
        <div className="leagues_list">
          {loading && <LoadingSpinner />}
          {!loading &&
            searchResults.model.map((l) => {
              return (
                <div className="league" key={l.couponId}>
                  <div className="league_icon">
                    <img src="images/league-mark.svg" alt="Premier League" />
                  </div>
                  <div className="league-split">
                    <div className="upper">
                      <div className="league_titles">
                        <h4>{l.productName}</h4>
                        <h3>{l.couponName}</h3>
                      </div>
                      <div className="league_details">
                        <div className="value">£5 league</div>
                        <div className="entries">150 entries</div>
                      </div>
                      <div className="league-buttons">
                        <Link to="/manage-account/settled-bets" className="">
                          <span className="sr-only">View prizes</span>
                          <i className="fal fa-pound-sign"></i>
                        </Link>
                        <Link to={`/enter-coupon/${l.couponId}`}>
                          <span className="sr-only">Edit league</span>
                          <i className="fal fa-pencil"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="lower">
                      <div className="position">{l.position}</div>
                    </div>
                  </div>
                  <div className="league_actions">
                    <Link to={`/enter-coupon/${l.couponId}`} className="link">
                      <span className="sr-only">View League</span>
                      <i className="fal fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpcomingLeagues;
