import React from "react";
import { Form } from "react-bootstrap";

const Transactions: React.FC<{}> = () => {
  return (
    <main className="add-card">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <div className="title_container">
        <h2>Transactions</h2>
        <p>Use the form below to search through your transaction history.</p>
        <a
          href=""
          className="toggle_filters btn btn-md btn-outline-light"
          id="toggle_transaction_filters"
        >
          <span className="sr-only">Toggle Filters</span>
          <i className="fas fa-filter"></i>
        </a>
      </div>

      <div className="table_filters d-none" id="account_transaction_filters">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("withdraw form submitted");
          }}
        >
          <div className="form-group">
            <label>Date from:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>Date to:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
          <div className="form-group">
            <label>Bet type:</label>
            <select className="form-control form-control-sm">
              <option>All</option>
              <option>Deposit</option>
              <option>Withdrawl</option>
              <option>Promotional</option>
              <option>Refund</option>
              <option>Bet</option>
              <option>Win</option>
              <option>Incentive</option>
            </select>
          </div>
          <div className="footer_btns">
            <a href="" className="btn btn-sm btn-primary">
              Search
            </a>
            <a className="text-link">Clear</a>
          </div>
        </Form>
      </div>

      <div
        id="transactions-table"
        className="table-container table-responsive-sm"
      >
        <table className="table table-dark table-hover leagues table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Amount</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>10/01/2020</td>
              <td>Deposit</td>
              <td>Withdrawl from card ending #1234</td>
              <td>£5.00</td>
              <td>232</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pagination error">
        <div className="no_of_results">
          <span className="results">
            <span className="first_result_current_page">1</span> to{" "}
            <span className="last_results_current_page">10</span> of{" "}
            <span className="total_results">70</span>{" "}
          </span>
        </div>
        <div className="pagination_navigation">
          <a href="" className="navigation_link disabled first">
            <span className="sr-only">First page</span>
            <i className="fal fa-angle-double-left"></i>
          </a>
          <a href="" className="navigation_link disabled prev">
            <span className="sr-only">Previous page</span>
            <i className="fal fa-angle-left"></i>
          </a>
          <span className="active page_number first">1</span>
          <a href="" className="page_number">
            2
          </a>
          <a href="" className="page_number">
            3
          </a>
          <a href="" className="page_number last">
            4
          </a>
          <a href="" className="navigation_link next">
            <span className="sr-only">Next page</span>
            <i className="fal fa-angle-right"></i>
          </a>
          <a href="" className="navigation_link last">
            <span className="sr-only">Last page</span>
            <i className="fal fa-angle-double-right"></i>
          </a>
        </div>
      </div>
    </main>
  );
};

export default Transactions;
