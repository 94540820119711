
  import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserBalanceSelector, currentUserInitialsSelector } from '../../store/currentUser/currentUserSelector';
import { formatCurrency } from '../../utils/NumberUtil';
import { Link } from 'react-router-dom';
  
 
  
  const AuthWidget: React.FC = () => {
  
    const initials = useSelector(currentUserInitialsSelector);
    const balance = useSelector(currentUserBalanceSelector);

    return (
        <div className="account loggedIn">
            <a href="" className="icon"><span className="sr-only">Notifications</span><i className="fal fa-bell" aria-hidden="true"></i></a>
            <a href="" className="icon"><span className="sr-only">Notifications</span><i className="fal fa-life-ring" aria-hidden="true"></i></a>
            <Link to="/manage-account/deposit" id="profile" className="profile"><span className="budget">{formatCurrency(Number(balance))}<span className="userProfile">{initials}</span></span></Link>
        </div>
    );
  };
  
  export default AuthWidget;
  