import React from "react";

interface ITeamVsProps {
  homeTeamName: string;
  homeTeamImageUrl: string;
  awayTeamName: string;
  awayTeamImageUrl: string;
}

const TeamVs: React.FC<ITeamVsProps> = ({
  homeTeamName,
  homeTeamImageUrl,
  awayTeamName,
  awayTeamImageUrl,
}: ITeamVsProps) => {
  return (
    <div className="fixture">
      <div className="team teamA">
        <p className="teamName">{homeTeamName}</p>
        <img
          src={homeTeamImageUrl ?? "/images/team-holding.png"}
          alt={homeTeamName}
          height="50px"
        />
      </div>
      <p className="vs">vs</p>
      <div className="team teamB">
        <p className="teamName">{awayTeamName}</p>
        <img
          src={awayTeamImageUrl ?? "/images/team-holding.png"}
          alt={awayTeamName}
          height="50px"
        />
      </div>
    </div>
  );
};

export default TeamVs;
