import React from 'react';
import { useSelector } from 'react-redux';
import { currentUserBalanceSelector, currentUserPromoBalanceSelector } from '../../store/currentUser/currentUserSelector';
import { formatCurrency } from '../../utils/NumberUtil';

const BalanceWidget: React.FC<{}> = () => {

    const balance = useSelector(currentUserBalanceSelector);
    const promoBalance = useSelector(currentUserPromoBalanceSelector);

    return (
        <div className="balance_container">
            <div className="balance"><span>Balance</span><span className="amount">{balance ? formatCurrency(Number(balance)) : '0.00'}</span></div>
            <div className="promo"><span>Free bets</span><span className="amount">{promoBalance ? formatCurrency(Number(promoBalance)) : '0.00'}</span></div>
        </div>
    );
};

export default BalanceWidget;
