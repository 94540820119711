import { UserXPDto } from '../../types/dto/userProfile/UserXPDto';
import { IAppState } from '../rootReducer';
import { ICurrentUserState } from './currentUserReducer';

export const currentUserFirstNameSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.profile && state.currentUser.profile.name ? `${state.currentUser.profile.name}` : '';
};

export const currentUserNameSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.profile && state.currentUser.profile.name && state.currentUser.profile.surname ? `${state.currentUser.profile.name} ${state.currentUser.profile.surname}` : '';
};

export const currentUserInitialsSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.profile && state.currentUser.profile.name && state.currentUser.profile.surname ? `${state.currentUser.profile.name[0]} ${state.currentUser.profile.surname[0]}` : '';
};

export const currentUserBalanceSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.balance && state.currentUser.balance.balance ? state.currentUser.balance.balance.toString() : '';
};

export const currentUserLevelSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.XP && state.currentUser.XP.currentLevelName ? state.currentUser.XP.currentLevelName : '1';
};

export const currentUserXPToNextLevelSelector = (state: IAppState): number => {
  return  state.currentUser && state.currentUser.XP && state.currentUser.XP.nextLevelXP ? state.currentUser.XP.nextLevelXP : 0;
};

export const currentUserXPSelector = (state: IAppState): number => {
  return  state.currentUser && state.currentUser.XP && state.currentUser.XP.currentXP ? state.currentUser.XP.currentXP : 0;
};

export const currentXPSelector = (state: IAppState): UserXPDto => {
  return  state.currentUser && state.currentUser.XP ? state.currentUser.XP : {};
};

export const currentUserPromoBalanceSelector = (state: IAppState): string => {
  return  state.currentUser && state.currentUser.balance && state.currentUser.balance.promoBalance ? state.currentUser.balance.promoBalance.toString() : '';
};

export const isLoadingBalanceSelector = (state: IAppState): boolean => {
  return  state.currentUser && state.currentUser.loadingBalance;
};

export const isLoadingXPSelector = (state: IAppState): boolean => {
  return  state.currentUser && state.currentUser.loadingXP;
};

export const isLoadingProfileSelector = (state: IAppState): boolean => {
  return  state.currentUser && state.currentUser.loadingBalance;
};
