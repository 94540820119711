import { BoolCheckResultDto } from '../../types/dto/BoolCheckResultDto';
import LeagueEntryFixtureAnswersDto from '../../types/dto/leagueEntry/LeagueEntryFixtureAnswersDto';
import LeagueEntryPositionDto from '../../types/dto/leagueEntry/LeagueEntryPositionDto';
import LeagueEntrySummaryDto from '../../types/dto/leagueEntry/LeagueEntrySummaryDto';
import { ResponseSetDto } from '../../types/dto/ResponseSetDto';
import LeagueEntryStatusEnum from '../../types/enums/LeagueEntryStatus';
import { genericProtectedGet } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/LeagueEntry/`;

export function getCurrentUserLeagues(
  status?: LeagueEntryStatusEnum,
  from?: Date,
  to?: Date,
  leagueId?: number,
  page?: number,
  limit?: number
): Promise<ResponseSetDto<LeagueEntrySummaryDto>> {
  const url = new URL(`${baseApiUrl}CurrentUser`);

  if(leagueId){
    url.searchParams.append('leagueId', leagueId.toString());
  }

  if(status){
    url.searchParams.append('status', status.toString());
  }

  if(from){
    url.searchParams.append('from', from ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}` : "");
  }

  if(to){
    url.searchParams.append('to', to ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}` : "");
  }

  if(page){
    url.searchParams.append('page', page.toString());
  }

  if(limit){
    url.searchParams.append('limit', limit.toString());
  }

  return genericProtectedGet<ResponseSetDto<LeagueEntrySummaryDto>>(url.toString());
}

export function getLeagueEntryPositions(leagueId: number, leagueEntryIds: number[]): Promise<LeagueEntryPositionDto[]> {
  const url = new URL(`${baseApiUrl}Positions`);
  
  url.searchParams.append('leagueId', leagueId.toString());

  for(var x = 0; x < leagueEntryIds.length; x++){
    url.searchParams.append('leagueEntryIds', leagueEntryIds[x].toString());
  }

  return genericProtectedGet<LeagueEntryPositionDto[]>(url.toString());
}

export function getUserHasLeagueEntry(couponId: number): Promise<BoolCheckResultDto> {
  const url = new URL(`${baseApiUrl}UserHasLeagueEntry`);
  
  url.searchParams.append('couponId', couponId.toString());

  return genericProtectedGet<BoolCheckResultDto>(url.toString());
}

export function getLeagueEntryAnswers(leagueEntryId: number): Promise<LeagueEntryFixtureAnswersDto[]> {
  const url = new URL(`${baseApiUrl}${leagueEntryId}/UserHAnswers`);
  return genericProtectedGet<LeagueEntryFixtureAnswersDto[]>(url.toString());
}