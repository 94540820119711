import { CouponSummaryDto } from '../../types/dto/coupon/CouponSummaryDto';
import { CouponFixtureSummaryDto } from '../../types/dto/couponFixture/CouponFixtureSummaryDto';
import CouponFixtureQuestionDto from '../../types/dto/couponFixtureQuestion/CouponFixtureQuestionDto';
import LeagueSummaryDto from '../../types/dto/league/LeagueSummaryDto';
import ParticipantSummaryDto from '../../types/dto/participant/ParticipantSummaryDto';
import { ResponseSetDto } from '../../types/dto/ResponseSetDto';
import TeamDto from '../../types/dto/team/TeamDto';
import { genericProtectedGet } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/Coupon/`;

export function getFeaturedCoupon(): Promise<CouponSummaryDto> {
  const url = new URL(`${baseApiUrl}Featured`);
  return genericProtectedGet<CouponSummaryDto>(url.toString());
}

export function getCoupons(
  searchText: string,
  from: Date,
  to: Date,
  page: number,
  limit: number
): Promise<ResponseSetDto<CouponSummaryDto>> {
  const url = new URL(`${baseApiUrl}Search`);
  
  if(searchText) url.searchParams.append('searchText', searchText);
  
  url.searchParams.append('from', from ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}` : "");
  url.searchParams.append('to', to ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}` : "");
  url.searchParams.append('page', page.toString());
  url.searchParams.append('limit', limit.toString());
  return genericProtectedGet<ResponseSetDto<CouponSummaryDto>>(url.toString());
}

export function getCouponSummary(
  couponId: number
): Promise<CouponSummaryDto> {
  const url = new URL(`${baseApiUrl}${couponId}/Summary`);
  return genericProtectedGet<CouponSummaryDto>(url.toString());
}

export function getCouponTeams(
  couponId: number
): Promise<TeamDto[]> {
  const url = new URL(`${baseApiUrl}${couponId}/Teams`);
  return genericProtectedGet<TeamDto[]>(url.toString());
}

export function getCouponTeamParticipants(
  couponId: number,
  teamId: number
): Promise<ParticipantSummaryDto[]> {
  const url = new URL(`${baseApiUrl}${couponId}/Teams/${teamId}`);
  return genericProtectedGet<ParticipantSummaryDto[]>(url.toString());
}

export function getCouponLeagues(
  couponId: number
): Promise<LeagueSummaryDto[]> {
  const url = new URL(`${baseApiUrl}${couponId}/Leagues`);
  return genericProtectedGet<LeagueSummaryDto[]>(url.toString());
}

export function getCouponFixtures(
  couponId: number
): Promise<CouponFixtureSummaryDto[]> {
  const url = new URL(`${baseApiUrl}${couponId}/Fixtures`);
  return genericProtectedGet<CouponFixtureSummaryDto[]>(url.toString());
}

export function getCouponFixtureQuestions(
  couponId: number,
  fixtureId: number
): Promise<CouponFixtureQuestionDto[]> {
  const url = new URL(`${baseApiUrl}${couponId}/Fixtures/${fixtureId}/Questions`);
  return genericProtectedGet<CouponFixtureQuestionDto[]>(url.toString());
}