import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isAuthenticatedSelector } from '../store/authentication/authenticationSelector';



export const ErrorPage: React.FC<{}> = () => {

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  return (
    <div>
      <div>
        <span>
          <i className="fal fa-exclamation-triangle" />
        </span>
        <h1>Application Error </h1>
        <h3>
          This application has encountered an error, please contact your support team.
        </h3>
        <Link to="/">
          <i className="far fa-chevron-left" />
          {isAuthenticated ? ` Back to Dashboard` : ` Back to login`}
        </Link>
      </div>
    </div>
  );
};
