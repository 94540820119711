import React from "react";
import CurrentUserLeagues from "../../components/common/user/CurrentUserLeagues";
import UpcomingLeagues from "../../components/common/user/UpcomingLeagues";
import BalanceWidget from "../../components/widget/BalanceWidget";
import ChatWidget from "../../components/widget/ChatWidget";
import SuperLeagueWidget from "../../components/widget/SuperLeagueWidget";
import { isCurrentUrl } from "../../utils/UrlUtil";
import { Link, Route, Routes } from "react-router-dom";
import MyBets from "./components/MyBets";
import FeaturedCoupon from "../../components/widget/FeaturedCoupon";
import LeaguePageTabs from "./components/LeaguePageTabs";

const UpcomingLeaguesPage: React.FC<{}> = () => {
  return (
    <main id="upcoming-leagues-page" className="three_col_inner">
      <div className="dashboard_inner">
        <LeaguePageTabs />
        <UpcomingLeagues />
        <FeaturedCoupon />
      </div>
      <div className="league_sidebar sidebar">
        <BalanceWidget />
        <SuperLeagueWidget />
      </div>
      <ChatWidget />
    </main>
  );
};

export default UpcomingLeaguesPage;
