import { CouponSummaryDto } from "../../../types/dto/coupon/CouponSummaryDto";

interface IProps {
  couponSummary: CouponSummaryDto | undefined;
  enterLeagueAgain: () => void;
  reuseSelection: () => void;
}

const SuccessfulEntryPanel: React.FC<IProps> = ({
  couponSummary,
  enterLeagueAgain,
  reuseSelection,
}) => (
  <div id="enter-league-success" className="card">
    <div className="card-body">
      <img src="/images/success-tick.svg" />
      <h4>Successful League Entry</h4>
      {couponSummary && (
        <h5>
          You have successfully entered <br /> '{couponSummary.couponName}'
        </h5>
      )}
    </div>
    <div className="card-footer">
      <a href="#" className="btn btn-primary" onClick={enterLeagueAgain}>
        Enter Again
      </a>
      <a href="#" className="btn btn-outline" onClick={reuseSelection}>
        Re-use Selection
      </a>
    </div>
  </div>
);

export default SuccessfulEntryPanel;
