import { ActionKeys } from './currentUserActions';
import { store } from '../../index';
import { UserBalanceDto } from '../../types/dto/userProfile/UserBalanceDto';
import { UserProfileDto } from '../../types/dto/userProfile/UserProfileDto';
import { UserXPDto } from '../../types/dto/userProfile/UserXPDto';

export const currentUserLoggedOutCreator = (): void => {
  store.dispatch({
    type: ActionKeys.USER_LOGGED_OUT
  });
};

export const setLoadingUserBalanceCreator = (): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADING_USER_BALANCE
  });
};

export const setLoadedUserBalanceCreator = (balance: UserBalanceDto): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADED_USER_BALANCE,
    balance
  });
};

export const setLoadingUserXPCreator = (): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADING_USER_XP
  });
};

export const setLoadedUserXPCreator = (balance: UserXPDto): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADED_USER_XP,
    balance
  });
};

export const setLoadingUserProfileCreator = (): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADING_USER_PROFILE
  });
};

export const setLoadedUserProfileCreator = (profile: UserProfileDto): void => {
  store.dispatch({
    type: ActionKeys.USER_LOADED_USER_PROFILE,
    profile
  });
};