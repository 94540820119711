export const formatXP = (credits: number, defaultReturn = '') => {
  if (!credits) return defaultReturn;
  return credits.toLocaleString();
};

export const formatCurrency = (amount: number, defaultReturn = '') => {
  if (!amount) return defaultReturn;
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount);
};

export const ordinalSuffixOf = (i: number) => {

  if(!i) return "0";

  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}