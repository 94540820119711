export enum ActionKeys {
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_REFRESHED = 'USER_REFRESHED',
  LOGOUT = 'LOGOUT',
}

export interface IUserAuthenticatedAction {
  type: ActionKeys;
  token: string;
  refreshToken: string;
}

export interface ILogoutAction {
  type: ActionKeys;
}

export type Action = IUserAuthenticatedAction;
