import React from "react";
import { Form } from "react-bootstrap";

const Deposit: React.FC<{}> = () => {
  return (
    <main className="deposit">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <h2>Deposit funds</h2>
      <p>All deposits will appear as “GFinity” on your banking statement.</p>
      <h3>Select a deposit method</h3>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Deposit form submitted");
        }}
      >
        <div className="alert alert-danger">
          <span>
            <h2>One or more of your cards has an issue</h2>
            <p>
              Your card ending in #0000 has been disabled due to inactivity,
              please click here to re-enter the card
            </p>
            <p>
              Your card ending in #0001 has expired, please click here to
              re-enter the card
            </p>
          </span>
        </div>
        <ul className="addedCards">
          <li>
            <span className="type">
              <i className="fab fa-cc-visa"></i>
            </span>
            <span className="number">ending #0000</span>
            <span className="status">
              <input type="radio" id="card1" name="Cards" />
              <label htmlFor="card1">Select</label>
            </span>
          </li>
          <li className="inactive">
            <span className="type">
              <i className="fab fa-cc-mastercard"></i>
            </span>
            <span className="number">ending #1234</span>
            <span className="status">
              <input type="radio" id="card2" name="Cards" />
              <label htmlFor="card2">Select</label>
            </span>
          </li>
          <li className="expired">
            <span className="type">
              <i className="fab fa-cc-apple-pay"></i>
            </span>
            <span className="number">ending #3333</span>
            <span className="status">
              <span>Expired</span>
            </span>
          </li>
        </ul>
        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                id="DOB_Day"
                name="DOB_Day"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Day" className="">
                Deposit Amount <span>*</span>
              </label>
              <span
                className="field-validation-valid"
                data-valmsg-for="TelNo"
                data-valmsg-replace="true"
              ></span>
              <span className="field-validation-valid-check"></span>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                id="DOB_Day"
                name="DOB_Day"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Day" className="">
                CVV <span>*</span>
              </label>
              <span
                className="field-validation-valid"
                data-valmsg-for="TelNo"
                data-valmsg-replace="true"
              ></span>
              <span className="field-validation-valid-check"></span>
            </div>
          </div>
        </div>
        <div className="footer_btns">
          <button className="btn btn-primary btn-lg">
            <i className="fa fa-lock"></i>Deposit
          </button>
          <a href="" className="btn btn-outline-primary btn-lg">
            <i className="fa fa-plus"></i>Add another card
          </a>
        </div>
      </Form>
    </main>
  );
};

export default Deposit;
