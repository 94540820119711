import React from "react";
import CurrentUserLeagues from "../../components/common/user/CurrentUserLeagues";
import SuperLeagueWidget from "../../components/widget/SuperLeagueWidget";
import ActivityLogWidget from "../../components/widget/ActivityLogWidget";
import ChatWidget from "../../components/widget/ChatWidget";
import Header from "./components/Header";
import Stats from "./components/Stats";
import UpcomingCouponWidget from "../../components/widget/UpcomingCouponWidget";
import FeaturedCoupon from "../../components/widget/FeaturedCoupon";

const DashboardPage: React.FC<{}> = () => {
  return (
    <main id="dashboard-page" className="three_col_inner">
      <div className="dashboard_inner">
        <Header />
        <Stats />
        <FeaturedCoupon />
        <CurrentUserLeagues />

        <div className="row">
          <div className="col-4">
            <a href="" className="league_crossSell">
              <span>Football advert</span>
            </a>
          </div>
          <div className="col-4">
            <a href="" className="league_crossSell">
              <span>ESport advert</span>
            </a>
          </div>
          <div className="col-4">
            <a href="" className="league_crossSell">
              <span>Horse racing advert</span>
            </a>
          </div>
        </div>
      </div>
      <div className="dashboard_sidebar sidebar">
        <SuperLeagueWidget />
        <UpcomingCouponWidget />
        <ActivityLogWidget />
      </div>
      <ChatWidget />
    </main>
  );
};

export default DashboardPage;
