import { legacy_createStore, compose } from "redux";
import { History } from "history";
import { rootReducer } from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose your storage engine

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authenticationState"], // Persist the authentication reducer
};

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export default function configureStore(history: History) {
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  const store = legacy_createStore(persistedReducer);

  return { store, persistor: persistStore(store) };
}
