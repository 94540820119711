import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { ReactToastifyContainer } from '../components/common/ReactToastifyContainer';

interface IMinLayoutProps {

}

const MinLayout: React.FC<IMinLayoutProps> = (props) => {
  return (
    <main className="authentication login">
      <img src="/images/ES-logo.svg" className="logo" alt="Esports Leagues" />
      <div className="form_container">
        <Outlet />
      </div>
      <ReactToastifyContainer />
      <div className="copy">&copy; ESports Leagues 2021</div>
    </main>
  );
}

export default MinLayout;
