const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const dayOrdinal = function(d: number) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const dateRangeText = (start?: Date, end?: Date) => {

  if(!start || !end) return "-";

  let startString = "";
  let endString = "";

  endString = endString + end.getDate().toString() + dayOrdinal(end.getDate());

  if(start.getDate() !== end.getDate() || start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()){
    startString = startString + start.getDate().toString() + dayOrdinal(start.getDate());
  }

  endString = `${endString} ${monthNames[end.getMonth()]}`;

  if(start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()){
    startString = `${startString} ${monthNames[start.getMonth()]}`;
  }

  endString = `${endString} ${end.getFullYear()}`;

  if(start.getFullYear() !== end.getFullYear()){
    startString = `${startString} ${start.getFullYear()}`;
  }

  return startString === "" ? endString : startString + " - " + endString;

};

export const fullDateString = (date: Date, defaultResult = "-") => {

  if(!date) return defaultResult;

  return `${dayNames[date.getDay()]} ${date.getDate().toString() + dayOrdinal(date.getDate())} ${monthNames[date.getMonth()]}`;

};

export const tableDateString = (date: Date, defaultResult = "-") => {

    if(!date) return defaultResult;

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    let suffix = dayOrdinal(dayOfMonth);
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day} ${dayOfMonth}${suffix} ${hours}:${minutes}`;
}
