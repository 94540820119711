import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouteProps } from 'react-router';
import { isAuthenticatedSelector } from '../../store/authentication/authenticationSelector';

export type IGuardedRouteProps = RouteProps;

export const GuardedRoute: React.FC<IGuardedRouteProps> = (props: IGuardedRouteProps) => {
  const isSignedIn = useSelector(isAuthenticatedSelector);

  return !isSignedIn ? <Navigate to="/" /> : <Outlet />;
};

export default GuardedRoute;
