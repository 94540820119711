import { TransactionErrorContainerDto } from '../types/dto/TransactionErrorContainerDto';

function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const setFieldErrorsFromTransactionErrors = <t>(name: TransactionErrorContainerDto, setError: Function) => {

  if(!name) return;

  var keys = Object.keys(name);
  keys.forEach((x) => {
    const propName = x as keyof t;
    const nameStr = camelize(propName.toString().indexOf(".") !== -1 ? propName.toString() : camelize(propName.toString()));
    setError(
      name[x].map((e) => {
        return { type: nameStr, message: e, name: nameStr };
      })
    );
  });
};
