import { combineReducers, Action } from "redux";
import { History } from "history";
import { reducer as oidcReducer } from "redux-oidc";
import { User } from "oidc-client";
import { ThunkAction } from "redux-thunk";
import {
  IAuthenticationState,
  authenticationReducer,
} from "./authentication/authenticationReducer";
import {
  INotificationState,
  notificationReducer,
} from "./notification/notificationReducer";
import { IDialogState, dialogReducer } from "./dialog/dialogReducer";
import { IDrawState, drawReducer } from "./draw/drawReducer";
import {
  currentUserReducer,
  ICurrentUserState,
} from "./currentUser/currentUserReducer";

export interface IOidcState {
  isLoadingUser: boolean;
  user: User;
}

export interface IAppState {
  oidcState: IOidcState;
  authenticationState: IAuthenticationState;
  notificationState: INotificationState;
  router: unknown; // TODO: Create interface for known properties
  dialog: IDialogState;
  draw: IDrawState;
  currentUser: ICurrentUserState;
}

export const rootReducer = (history: History) =>
  combineReducers({
    oidcState: oidcReducer,
    authenticationState: authenticationReducer,
    notificationState: notificationReducer,
    dialog: dialogReducer,
    draw: drawReducer,
    currentUser: currentUserReducer,
  });

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IAppState,
  unknown,
  Action<string>
>;
