/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  getCouponFixtureQuestions,
  getCouponFixtures,
  getCouponSummary,
  getCouponTeamParticipants,
  getCouponTeams,
} from "../../api/ESports/couponService";
import { CouponSummaryDto } from "../../types/dto/coupon/CouponSummaryDto";
import { dateRangeText, tableDateString } from "../../utils/DateUtil";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { CouponFixtureSummaryDto } from "../../types/dto/couponFixture/CouponFixtureSummaryDto";
import CouponFixtureQuestionDto from "../../types/dto/couponFixtureQuestion/CouponFixtureQuestionDto";
import TeamDto from "../../types/dto/team/TeamDto";
import ParticipantSummaryModel from "../../types/dto/participant/ParticipantSummaryDto";
import { enterLeague, updateSelection } from "../../api/ESports/leagueService";
import {
  createErrorToast,
  createGenericErrorToast,
} from "../../components/common/ReactToastifyContainer";
import {
  getLeagueEntryAnswers,
  getUserHasLeagueEntry,
} from "../../api/ESports/leagueEntryService";
import LeagueEntryFixtureAnswersDto from "../../types/dto/leagueEntry/LeagueEntryFixtureAnswersDto";
import TeamVs from "../../components/common/team/TeamVs";
import SuccessfulEntryPanel from "./components/SuccessfulEntryPanel";

interface ITeamAndParticipants extends TeamDto {
  participants: ParticipantSummaryModel[];
}

interface ICouponFixtureQuestionAnswer extends CouponFixtureQuestionDto {
  answer: number;
  childQuestion: ICouponFixtureQuestionAnswer;
}

interface IFixtureQuestion extends CouponFixtureSummaryDto {
  loading: boolean;
  loaded: boolean;
  questions: ICouponFixtureQuestionAnswer[];
  questionIndex: number;
}

const EnterCouponPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { id, entryId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [couponSummary, setCouponSummary] = useState<CouponSummaryDto>();
  const [loadingFixtures, setLoadingFixtures] = useState<boolean>(false);
  const [couponFixtures, setCouponFixtures] = useState<IFixtureQuestion[]>([]);
  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
  const [requiresBetName, setRequiresBetName] = useState<boolean>(false);
  const [betNameError, setBetNameError] = useState<string>();
  const [canEditBetName, setCanEditBetName] = useState<boolean>(true);
  const [betName, setBetName] = useState<string>();
  const [teams, setTeams] = useState<ITeamAndParticipants[]>([]);
  const [selectedFixtureIndex, setSelectedFixtureIndex] = useState<number>(0);
  const [showHelpMessage, setShowHelpMessage] = useState<boolean>(true);
  const [complete, setComplete] = useState<boolean>(false);

  console.log({ couponFixtures });

  useEffect(() => {
    var cancelled = false;
    setLoading(true);
    setLoadingFixtures(true);
    setLoadingTeams(true);
    getCouponSummary(Number(id))
      .then((result) => {
        if (cancelled) return;

        if (new Date(result.entryCloseDate) < new Date()) {
          createErrorToast("Entries no longer available for this league");
          navigate(`/dashboard`);
        }

        setCouponSummary(result);

        setLoading(false);
      })
      .catch(() => {
        navigate("/error");
      });

    setCouponFixturesAndAnswers(cancelled);

    getCouponTeams(Number(id))
      .then((t) => {
        if (cancelled) return;

        Promise.all(
          t.map((x) => getCouponTeamParticipants(Number(id), x.teamId))
        ).then((p) => {
          if (cancelled) return;

          setTeams(
            t.map((x, i) => {
              return { ...x, participants: p[i] };
            })
          );
          setLoadingTeams(false);
        });
      })
      .catch(() => {
        navigate("/error");
      });

    return () => {
      cancelled = true;
    };
  }, [id]);

  const setCouponFixturesAndAnswers = (cancelled: boolean) => {
    getCouponFixtures(Number(id))
      .then((fixtures) => {
        if (cancelled) return;

        const fixtureQuestions = fixtures.map((f) => {
          return {
            ...f,
            ...{
              loading: true,
              loaded: false,
              questions: [],
              questionIndex: 0,
            },
          };
        }) as IFixtureQuestion[];

        // for(var x = 0; x < fixtureQuestions.length; x++){
        //     fixtureQuestions[x].loading = false;
        //     fixtureQuestions[x].loaded = false;
        //     fixtureQuestions[x].questions = [];
        // }

        if (entryId) {
          getLeagueEntryAnswers(Number(entryId)).then((answers) => {
            if (answers && answers.length > 0) {
              setBetName(answers[0].betName);
              setCanEditBetName(false);
              setRequiresBetName(!!answers[0].betName);
            }
            setFixturesQuestions(fixtureQuestions, answers);
          });
        } else {
          getUserHasLeagueEntry(Number(id)).then((r) => {
            setRequiresBetName(r.result);
          });
          setFixturesQuestions(fixtureQuestions, []);
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };

  const setFixturesQuestions = (
    fixtureQuestions: IFixtureQuestion[],
    answers: LeagueEntryFixtureAnswersDto[]
  ) => {
    return Promise.all(
      fixtureQuestions.map((f) =>
        getCouponFixtureQuestions(Number(id), f.fixtureId)
      )
    )
      .then((questions) => {
        setCouponFixtures(
          fixtureQuestions.map((e, i) => {
            e.loading = false;
            e.loaded = true;
            e.questions = questions[i]
              .filter((q) => !q.isBonusPointsQuestion)
              .map((q) => {
                return {
                  ...q,
                  ...{
                    answer:
                      answers.filter(
                        (a) =>
                          a.couponFixtureQuestionId == q.couponFixtureQuestionId
                      ).length > 0
                        ? answers.filter(
                            (a) =>
                              a.couponFixtureQuestionId ==
                              q.couponFixtureQuestionId
                          )[0].answerValue
                        : -1,
                    childQuestion:
                      questions[i].filter(
                        (cq) =>
                          cq.parentCouponFixtureQuestionId ===
                          q.couponFixtureQuestionId
                      ).length > 0
                        ? {
                            ...questions[i].filter(
                              (cq) =>
                                cq.parentCouponFixtureQuestionId ===
                                q.couponFixtureQuestionId
                            )[0],
                            ...{
                              answer:
                                answers.filter(
                                  (a) =>
                                    a.couponFixtureQuestionId ==
                                    questions[i].filter(
                                      (cq) =>
                                        cq.parentCouponFixtureQuestionId ===
                                        q.couponFixtureQuestionId
                                    )[0].couponFixtureQuestionId
                                ).length > 0
                                  ? answers.filter(
                                      (a) =>
                                        a.couponFixtureQuestionId ==
                                        questions[i].filter(
                                          (cq) =>
                                            cq.parentCouponFixtureQuestionId ===
                                            q.couponFixtureQuestionId
                                        )[0].couponFixtureQuestionId
                                    )[0].answerValue
                                  : -1,
                            },
                          }
                        : undefined,
                  },
                };
              }) as ICouponFixtureQuestionAnswer[];
            return e;
          })
        );
        setSelectedFixtureIndex(0);
        setLoadingFixtures(false);
      })
      .then((questions) => {
        setCouponFixtures((e) => {
          return [...e];
        });
      })
      .catch(() => {
        navigate("/error");
      });
  };

  const enterCoupon = () => {
    setBetNameError("");

    if (requiresBetName && !betName) {
      setBetNameError("Please enter a bet name");
      return;
    }

    var selection = couponFixtures
      .flatMap((x) =>
        x.questions.flatMap((y) =>
          y.childQuestion ? [y, y.childQuestion] : [y]
        )
      )
      .map((x) => {
        return {
          couponFixtureQuestionId: x.couponFixtureQuestionId,
          answerValue: x.answer,
        };
      });

    var request = entryId
      ? updateSelection({ entryId: Number(entryId), selection: selection })
      : enterLeague({
          couponId: Number(id),
          betName: betName,
          selection: selection,
        });

    request
      .then((result) => {
        if (result.success) {
          // navigate(`/league/${result.model?.result}`);
          setComplete(true);
        } else {
          createGenericErrorToast();
        }
      })
      .catch(() => {
        createGenericErrorToast();
      });
  };

  const enterLeagueAgain = () => {
    navigate(`/enter-coupon/${id}`);
    setCouponFixturesAndAnswers(false);
    setBetName("");
    setCanEditBetName(true);
    setComplete(false);
  };

  const reuseSelection = () => {
    setBetName("");
    setCanEditBetName(true);
    setComplete(false);
    navigate(`/enter-coupon/${id}`);
  };

  const dates = dateRangeText(
    couponSummary?.startDate ? new Date(couponSummary.startDate) : undefined,
    couponSummary?.endDate ? new Date(couponSummary.endDate) : undefined
  );

  const renderer: CountdownRendererFn = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return "-";
    } else {
      // Render a countdown
      return (
        <div className="countdown_block">
          {days > 0 && (
            <span className="days">
              <span>{days}</span>d{" "}
            </span>
          )}
          {hours > 0 && (
            <span className="days">
              <span>{hours}</span>h{" "}
            </span>
          )}
          {minutes > 0 && (
            <span className="days">
              <span>{minutes}</span>m{" "}
            </span>
          )}
          {seconds > 0 && (
            <span className="days">
              <span>{seconds}</span>s
            </span>
          )}
        </div>
      );
    }
  };

  const entryCloseTime =
    couponSummary &&
    new Date(couponSummary.entryCloseDate).getTime() > new Date().getTime();

  const countdownDate = !entryCloseTime
    ? new Date(couponSummary?.startDate ?? "")
    : new Date(couponSummary?.entryCloseDate ?? "");

  const renderLoadingQuestion = () => {
    return <p>Loading</p>;
  };

  const flatQuestions = couponFixtures.flatMap((x) =>
    x.questions.flatMap((y) => (y.childQuestion ? [y, y.childQuestion] : [y]))
  );
  const completeQuestions = flatQuestions.filter((x) => x.answer >= 0).length;
  const totalQuestions = flatQuestions.length;
  const isComplete = completeQuestions === totalQuestions;

  const renderParticipantSelectQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ) => {
    if (!couponFixtures[selectedFixtureIndex]) return <React.Fragment />;

    if (loadingTeams) return renderLoadingQuestion();

    return (
      <React.Fragment key={question.questionId}>
        <div className="d-flex align-items-center">
          <h3>{question.question}</h3>
          <div className="badge badge-pill badge-info ml-3">
            {question.parentCouponFixtureQuestionId
              ? `${question.points} bonus `
              : question.points}
            pts
          </div>
        </div>
        <div className="team-group">
          <div className="team">
            <div className="teamTitle">
              {couponFixtures[selectedFixtureIndex]?.homeTeamName}
            </div>
            {teams
              .filter(
                (x) =>
                  x.teamId === couponFixtures[selectedFixtureIndex].homeTeamId
              )[0]
              ?.participants.map((p, i) => (
                <div
                  key={p.participantId}
                  className={`form-check  ${
                    question.answer === p.participantId ? "is-selected" : ""
                  }`}
                >
                  <div>
                    <input
                      type="radio"
                      id={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                      name={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                      value={
                        question.answer === p.participantId ? "true" : "false"
                      }
                      onClick={() => setAnswer(p.participantId)}
                    />
                    <label
                      htmlFor={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                    >
                      <span className="username">{p.participantName}</span>
                    </label>
                  </div>
                  <img
                    src={p.profileImageUrl ?? "/images/participant-holding.png"}
                    alt={p.participantName}
                  />
                </div>
              ))}
          </div>
          <div className="team">
            <div className="teamTitle">
              {couponFixtures[selectedFixtureIndex]?.awayTeamName}
            </div>
            {teams
              .filter(
                (x) =>
                  x.teamId === couponFixtures[selectedFixtureIndex].awayTeamId
              )[0]
              ?.participants.map((p) => {
                return (
                  <div
                    key={p.participantId}
                    className={`form-check  ${
                      question.answer === p.participantId ? "is-selected" : ""
                    }`}
                  >
                    <div>
                      <input
                        type="radio"
                        id={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                        name={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                        value={
                          question.answer === p.participantId ? "true" : "false"
                        }
                        onClick={() => setAnswer(p.participantId)}
                      />
                      <label
                        htmlFor={`ParticipantSelect${question.couponFixtureQuestionId}${p.participantId}`}
                      >
                        <span className="username">{p.participantName}</span>
                      </label>
                    </div>
                    <img
                      src={
                        p.profileImageUrl ?? "/images/participant-holding.png"
                      }
                      alt={p.participantName}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderNumericQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ) => {
    if (!question) return <React.Fragment />;

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <h3>{question.question}</h3>
          <div className="badge badge-pill badge-info ml-3">
            {question.parentCouponFixtureQuestionId
              ? `${question.points} bonus `
              : question.points}
            pts
          </div>
        </div>
        <div className="team-group">
          <div className="form-group numeric">
            <button
              onClick={() =>
                setAnswer(question.answer > 0 ? question.answer - 1 : 1)
              }
            >
              <i className="fa fa-minus" />
            </button>
            <input
              className={`form-control ${
                question.answer > 0 ? "populated" : ""
              }`}
              type="number"
              step="1"
              min="0"
              key={question.couponFixtureQuestionId}
              value={question.answer > 0 ? question.answer : ""}
              id={`NumericAnswer${question.couponFixtureQuestionId}`}
              name={`NumericAnswer${question.couponFixtureQuestionId}`}
              onChange={(e) => {
                if (!e.target) return;

                setAnswer(Number(e.target.value));
              }}
            />
            <button
              onClick={() =>
                setAnswer(question.answer > 0 ? question.answer + 1 : 1)
              }
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderYesNoQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ) => {
    if (!question) return <React.Fragment />;

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <h3>{question.question}</h3>
          <div className="badge badge-pill badge-info ml-3">
            {question.parentCouponFixtureQuestionId
              ? `${question.points} bonus `
              : question.points}
            pts
          </div>
        </div>
        <div className="team-group row">
          <div className="col-6 team">
            <div className="form-group">
              <input
                type="radio"
                id={`YesNoSelectYes${question.couponFixtureQuestionId}`}
                name={`YesNoSelectYes${question.couponFixtureQuestionId}`}
                checked={question.answer === 1}
                onClick={() => setAnswer(1)}
              />
              <label
                htmlFor={`YesNoSelectYes${question.couponFixtureQuestionId}`}
              >
                <span className="username">Yes</span>
              </label>
            </div>
          </div>
          <div className="col-6 team">
            <div className="form-group">
              <input
                type="radio"
                id={`YesNoSelectNo${question.couponFixtureQuestionId}`}
                name={`YesNoSelectNo${question.couponFixtureQuestionId}`}
                checked={question.answer === 0}
                onClick={() => setAnswer(0)}
              />
              <label
                htmlFor={`YesNoSelectNo${question.couponFixtureQuestionId}`}
              >
                <span className="username">No</span>
              </label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderTeamSelectQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ) => {
    const fixture = couponFixtures[selectedFixtureIndex];
    if (!fixture) return <React.Fragment />;

    const teamASelected = question.answer === fixture.homeTeamId;
    const teamBSelected = question.answer === fixture.awayTeamId;

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <h3>{question.question}</h3>
          <div className="badge badge-pill badge-info ml-3">
            {question.parentCouponFixtureQuestionId
              ? `${question.points} bonus `
              : question.points}
            pts
          </div>
        </div>
        <div className="team-group">
          <div className={`form-check  ${teamASelected ? "is-selected" : ""}`}>
            <div>
              <input
                className="form-check-input"
                type="radio"
                id={`TeamSelectHome${question.couponFixtureQuestionId}`}
                name={`TeamSelectHome${question.couponFixtureQuestionId}`}
                value={teamASelected ? "true" : "false"}
                onClick={() => setAnswer(fixture.homeTeamId ?? 0)}
              />
              <label
                className="form-check-label"
                htmlFor={`TeamSelectHome${question.couponFixtureQuestionId}`}
              >
                <span className="username">{fixture?.homeTeamName}</span>
              </label>
            </div>
            <img
              src={fixture.homeTeamImageUrl ?? "/images/team-holding.png"}
              alt={fixture.homeTeamName}
            />
          </div>
          <div className={`form-check  ${teamBSelected ? "is-selected" : ""}`}>
            <div>
              <input
                className="form-check-input"
                type="radio"
                id={`TeamSelectHome${question.couponFixtureQuestionId}`}
                name={`TeamSelectHome${question.couponFixtureQuestionId}`}
                value={teamBSelected ? "true" : "false"}
                onClick={() => setAnswer(fixture.awayTeamId ?? 0)}
              />
              <label
                className="form-check-label"
                htmlFor={`TeamSelectHome${question.couponFixtureQuestionId}`}
              >
                <span className="username">{fixture.awayTeamName}</span>
              </label>
            </div>
            <img
              src={fixture.awayTeamImageUrl ?? "/images/team-holding.png"}
              alt={fixture.awayTeamName}
            />
          </div>
        </div>
        {/* <div className="team-group">
          <div className="form-group">
            <input
              type="radio"
              id={`TeamSelectHome${question.couponFixtureQuestionId}`}
              name={`TeamSelectHome${question.couponFixtureQuestionId}`}
              checked={
                question.answer ===
                couponFixtures[selectedFixtureIndex]?.homeTeamId
              }
              onClick={() =>
                setAnswer(couponFixtures[selectedFixtureIndex]?.homeTeamId ?? 0)
              }
            />
            <label
              htmlFor={`TeamSelectHome${question.couponFixtureQuestionId}`}
            >
              <span className="username">
                {couponFixtures[selectedFixtureIndex]?.homeTeamName}
              </span>
              <span className="profile">
                <img
                  src={
                    couponFixtures[selectedFixtureIndex]?.homeTeamImageUrl ??
                    "/images/team-holding.png"
                  }
                  alt={couponFixtures[selectedFixtureIndex]?.homeTeamName}
                />
              </span>
            </label>
          </div>
          <div className="form-group">
            <input
              type="radio"
              id={`TeamSelectAway${question.couponFixtureQuestionId}`}
              name={`TeamSelectAway${question.couponFixtureQuestionId}`}
              checked={
                question.answer ===
                couponFixtures[selectedFixtureIndex]?.awayTeamId
              }
              onClick={() =>
                setAnswer(couponFixtures[selectedFixtureIndex]?.awayTeamId ?? 0)
              }
            />
            <label
              htmlFor={`TeamSelectAway${question.couponFixtureQuestionId}`}
            >
              <span className="username">
                {couponFixtures[selectedFixtureIndex]?.awayTeamName}
              </span>
              <span className="profile">
                <img
                  src={
                    couponFixtures[selectedFixtureIndex]?.awayTeamImageUrl ??
                    "/images/team-holding.png"
                  }
                  alt={couponFixtures[selectedFixtureIndex]?.awayTeamName}
                />
              </span>
            </label>
          </div>
        </div> */}
      </React.Fragment>
    );
  };

  const renderOptionSelectQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ) => {
    return <React.Fragment>Options Question</React.Fragment>;
  };

  const renderQuestion = (
    question: ICouponFixtureQuestionAnswer,
    setAnswer: (answer: number) => void
  ): React.ReactNode => {
    switch (question.questionTypeId) {
      case 1: // Participant Select
        return renderParticipantSelectQuestion(question, setAnswer);
      case 2: // Numeric
        return renderNumericQuestion(question, setAnswer);
      case 3: // Yes/No
        return renderYesNoQuestion(question, setAnswer);
      case 4: // Team Select
        return renderTeamSelectQuestion(question, setAnswer);
      case 5: // Option Select
        return renderOptionSelectQuestion(question, setAnswer);
    }
    return (
      <React.Fragment>Unknown type {question.questionTypeId}</React.Fragment>
    );
  };

  const renderQuestions = (fixtureIndex: number) => {
    if (
      couponFixtures[fixtureIndex]?.loading ||
      couponFixtures[fixtureIndex]?.loading
    )
      return <p>Loading</p>;

    const questions = couponFixtures[fixtureIndex]?.questions;

    if (!questions) return <React.Fragment />;

    return questions.map((question, questionIndex) => (
      <div className="question">
        {renderQuestion(question, (answer: number) =>
          setCouponFixtures((c) => {
            const copy = [...c];
            copy[fixtureIndex].questions[questionIndex].answer = answer;
            return copy;
          })
        )}
        {question.childQuestion && question.answer > 0 && (
          <div className="bonus-question">
            {renderQuestion(question.childQuestion, (answer: number) =>
              setCouponFixtures((c) => {
                c[fixtureIndex].questions[questionIndex].childQuestion.answer =
                  answer;
                return [...c];
              })
            )}
          </div>
        )}
      </div>
    ));
  };

  const renderParticipantSelectAnswer = (
    question: ICouponFixtureQuestionAnswer
  ) => {
    if (loadingTeams) return renderLoadingQuestion();

    const selectedParticipant = teams.flatMap((x) =>
      x.participants.filter((y) => y.participantId == question.answer)
    );

    if (selectedParticipant.length === 0) return <React.Fragment />;

    return (
      <div className="label">{selectedParticipant[0].participantName}</div>
    );
  };

  const renderParticipantImage = (question: ICouponFixtureQuestionAnswer) => {
    if (loadingTeams) return renderLoadingQuestion();

    const selectedParticipant = teams.flatMap((x) =>
      x.participants.filter((y) => y.participantId == question.answer)
    );

    if (selectedParticipant.length === 0) return <React.Fragment />;

    return (
      <img
        src={
          selectedParticipant[0].profileImageUrl ??
          "/images/participant-holding.png"
        }
        alt={selectedParticipant[0].participantName}
      />
    );
  };

  const renderNumericAnswer = (question: ICouponFixtureQuestionAnswer) => {
    return <div className="label">{question.answer}</div>;
  };

  const renderYesNoAnswer = (question: ICouponFixtureQuestionAnswer) => {
    return (
      <React.Fragment>
        <div className="label">{question.answer === 1 ? "Yes" : "No"}</div>
      </React.Fragment>
    );
  };

  const renderTeamSelectAnswer = (question: ICouponFixtureQuestionAnswer) => {
    const team = teams.filter((x) => x.teamId === question.answer);

    if (team.length === 0) return <React.Fragment />;

    return <div className="label">{team[0].teamDescription}</div>;
  };

  const renderTeamImage = (question: ICouponFixtureQuestionAnswer) => {
    const team = teams.filter((x) => x.teamId === question.answer);

    if (team.length === 0) return <React.Fragment />;

    return (
      <img
        src={team[0].profileLogoUrl ?? "/images/team-holding.png"}
        alt={team[0].teamDescription}
      />
    );
  };

  const renderOptionSelectAnswer = (question: ICouponFixtureQuestionAnswer) => {
    return <React.Fragment>Options Answer</React.Fragment>;
  };

  const renderAnswer = (
    question: ICouponFixtureQuestionAnswer
  ): React.ReactNode => {
    switch (question.questionTypeId) {
      case 1: // Participant Select
        return renderParticipantSelectAnswer(question);
      case 2: // Numeric
        return renderNumericAnswer(question);
      case 3: // Yes/No
        return renderYesNoAnswer(question);
      case 4: // Team Select
        return renderTeamSelectAnswer(question);
      case 5: // Option Select
        return renderOptionSelectAnswer(question);
    }
    return (
      <React.Fragment>Unknown type {question.questionTypeId}</React.Fragment>
    );
  };

  const renderAnswerImage = (
    question: ICouponFixtureQuestionAnswer
  ): React.ReactNode => {
    switch (question.questionTypeId) {
      case 1: // Participant Select
        return renderParticipantImage(question);
      case 4: // Team Select
        return renderTeamImage(question);
    }
    return <React.Fragment></React.Fragment>;
  };

  const renderAnswerContainer = (
    question: ICouponFixtureQuestionAnswer,
    viewQuestion: () => void,
    isBonus: boolean
  ): React.ReactNode => {
    return (
      <div className="item">
        <div className="item-answer">
          <h4>{question.question}</h4>
          {renderAnswer(question)}
        </div>
        <div className="badge badge-pill badge-info">
          {question.parentCouponFixtureQuestionId
            ? `${question.points} bonus `
            : question.points}
          pts
        </div>
        {renderAnswerImage(question)}
      </div>
    );
  };

  const renderTeamsForFixtureQuestionAnswer = (
    fixtureQA: ICouponFixtureQuestionAnswer
  ) => {
    var fixture = couponFixtures.filter(
      (x) => x.couponFixtureId == fixtureQA.couponFixtureId
    )[0];
    return renderTeams(
      fixture.homeTeamName,
      fixture.homeTeamImageUrl,
      fixture.awayTeamName,
      fixture.awayTeamImageUrl
    );
  };

  const renderTeamsForFixture = (fixture: IFixtureQuestion) => {
    return renderTeams(
      fixture.homeTeamName,
      fixture.homeTeamImageUrl,
      fixture.awayTeamName,
      fixture.awayTeamImageUrl
    );
  };

  const renderTeams = (
    homeTeamName: string,
    homeTeamImageUrl: string,
    awayTeamName: string,
    awayTeamImageUrl: string
  ) => {
    return (
      <TeamVs
        homeTeamName={homeTeamName}
        homeTeamImageUrl={homeTeamImageUrl}
        awayTeamName={awayTeamName}
        awayTeamImageUrl={awayTeamImageUrl}
      />
    );
  };

  const renderAnswers = () => {
    const noAnswers = couponFixtures.every(
      (fixture) => fixture.questions.filter((x) => x.answer > 0).length == 0
    );

    if (noAnswers) {
      return (
        <div className="bet-panel no-answers">
          Please make your bet selection to enter the league
        </div>
      );
    }

    return couponFixtures.map((fixture, fixtureIndex) => {
      const noAnswersForFixture =
        fixture.questions.filter((x) => x.answer > 0).length == 0;

      return (
        <div
          className={`bet-panel level1 ${
            noAnswersForFixture ? "upcoming" : ""
          }`}
          key={fixture.fixtureId}
        >
          {renderTeamsForFixture(fixture)}
          {fixture.questions.map((question, questionIndex) => {
            if (question.answer < 0) return <React.Fragment />;

            return (
              <React.Fragment key={question.couponFixtureQuestionId}>
                {renderAnswerContainer(
                  question,
                  () => {
                    setCouponFixtures((c) => {
                      c[fixtureIndex].questionIndex = questionIndex;
                      return [...c];
                    });
                    setSelectedFixtureIndex(fixtureIndex);
                  },
                  false
                )}
                {question.childQuestion &&
                  question.childQuestion.answer >= 0 && (
                    <div key={question.childQuestion.couponFixtureQuestionId}>
                      {renderAnswerContainer(
                        question.childQuestion,
                        () => {
                          setCouponFixtures((c) => {
                            c[fixtureIndex].questionIndex = questionIndex;
                            return [...c];
                          });
                          setSelectedFixtureIndex(fixtureIndex);
                        },
                        true
                      )}
                    </div>
                  )}
              </React.Fragment>
            );
          })}
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      {loading && <p>Loading</p>}
      {!loading && couponFixtures.length > 0 && (
        <div id="enter-league">
          <main>
            <div className="league-head">
              <div className="title-row">
                <div className="title">
                  <h3>Enter League</h3>
                  <div className="d-flex">
                    <h4>{couponSummary?.couponName}</h4>
                    <div className="event-name ml-4">
                      {couponSummary?.productName}
                    </div>
                  </div>
                </div>
                <div className="countdown">
                  <h4>{entryCloseTime ? "Entries close in" : "Starts in"}</h4>
                  <Countdown date={countdownDate} renderer={renderer} />
                </div>
              </div>
            </div>
            <div className="entry-body">
              {complete && (
                <SuccessfulEntryPanel
                  reuseSelection={reuseSelection}
                  couponSummary={couponSummary}
                  enterLeagueAgain={enterLeagueAgain}
                />
              )}
              {!complete && (
                <React.Fragment>
                  {showHelpMessage && (
                    <div className="card help-message">
                      <div className="card-body">
                        <h3>How to enter</h3>
                        <div className="breadcrumb">
                          <ul>
                            <li className="active">
                              <span className="counter">
                                1<span className="sr-only">Step 1</span>
                              </span>
                              <span>
                                Select a stake below to enter a league{" "}
                              </span>
                            </li>
                            <li>
                              <span className="counter">
                                2<span className="sr-only">Step 2</span>
                              </span>
                              <span>
                                Pick six fixtures where you predict lost of
                                goals
                              </span>
                            </li>
                            <li>
                              <span className="counter">
                                3<span className="sr-only">Step 3</span>
                              </span>
                              <span>More goals - more points</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-footer">
                        <a href="" className="text-link">
                          View points
                        </a>
                        <span>Most points wins the league</span>
                        <a
                          onClick={() => setShowHelpMessage(false)}
                          className="btn btn-md btn-outline-secondary confirmation"
                        >
                          Ok got it
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="entry-name">
                    <h3>
                      Pick your selection below to enter the £5 stake league
                    </h3>
                    {/* <span className="league_status">{couponSummary?.statusName}</span> */}
                  </div>
                  <div className="accordion" id="accordionExample">
                    {couponFixtures.map((fixture, couponFixtureIndex) => {
                      const allQuestionsAnswered =
                        fixture.questions.filter((q) => q.answer < 0).length ===
                        0;

                      const atLeastOneQuestionAnswered = fixture.questions.some(
                        (question) => question.answer > 0
                      );

                      return (
                        <div
                          className={`card ${
                            selectedFixtureIndex === couponFixtureIndex
                              ? "active"
                              : ""
                          } ${allQuestionsAnswered ? "completed" : ""}`}
                          onClick={() =>
                            setSelectedFixtureIndex(couponFixtureIndex)
                          }
                          key={fixture.fixtureId}
                        >
                          <div className="card-header" id="headingOne">
                            <i
                              className={`fal fa-chevron-${
                                selectedFixtureIndex === couponFixtureIndex
                                  ? "up"
                                  : "down"
                              }`}
                            ></i>
                            {renderTeamsForFixture(fixture)}
                            <p className="date">
                              {tableDateString(new Date(fixture.time))}
                            </p>
                            <span
                              className={`badge badge-pill badge-${
                                atLeastOneQuestionAnswered ? "primary" : "light"
                              } ${allQuestionsAnswered ? "all-answered" : ""}`}
                            >
                              {
                                fixture.questions.filter((q) => q.answer > 0)
                                  .length
                              }
                              /{fixture.questions.length}
                            </span>
                            {allQuestionsAnswered && (
                              <i className="fas fa-check-circle"></i>
                            )}
                          </div>
                          <div
                            id="collapseOne"
                            className={`collapse ${
                              selectedFixtureIndex === couponFixtureIndex
                                ? "show"
                                : ""
                            } entry_form`}
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body questions">
                              {renderQuestions(selectedFixtureIndex)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
            </div>
          </main>
          <div className="betslip">
            <div className="title">
              <h2>Your bet</h2>
              <div className="badge badge-pill badge-info">
                {flatQuestions.filter((x) => x.answer >= 0).length}/
                {flatQuestions.length}
              </div>
            </div>
            {renderAnswers()}
            {/* <div className="stake">
                    <h3>Choose your stake</h3>
                    <div className="btn_group row">
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_2_50" name="stake"/>
                        <label htmlFor="stake_2_50">
                            <span className="amount">£2.50</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_5" name="stake"/>
                        <label htmlFor="stake_5">
                            <span className="amount">£5.00</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_10" name="stake"/>
                        <label htmlFor="stake_10">
                            <span className="amount">£10.00</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_20" name="stake"/>
                        <label htmlFor="stake_20">
                            <span className="amount">£20.00</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_50" name="stake"/>
                        <label htmlFor="stake_50">
                            <span className="amount">£50.00</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                        <div className="col-4 form-group">
                        <input type="radio"  id="stake_100" name="stake"/>
                        <label htmlFor="stake_100">
                            <span className="amount">£100.00</span>
                            <span className="entries">0 Players</span>
                        </label>
                        </div>
                    </div>
                    </div> */}
            {requiresBetName && (
              <div className="stake">
                <h3>Bet Name</h3>
                <input
                  className="form-control"
                  key={"BetNameInput"}
                  value={betName}
                  id={`BetName`}
                  name={`BetName`}
                  readOnly={!canEditBetName}
                  onChange={(e) => {
                    if (!e.target) return;

                    setBetName(e.target.value);
                  }}
                />
                {betNameError && (
                  <span className="alert alert-danger">{betNameError}</span>
                )}
              </div>
            )}
            <div className="btn-container">
              {isComplete && (
                <button
                  className="btn btn-md btn-primary btn-block"
                  onClick={enterCoupon}
                >
                  {entryId ? "Edit Bet" : "Enter £5.00 league"}
                </button>
              )}
              {/* <a href="" className="btn btn-md btn-light btn-block">Edit Bet</a> */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EnterCouponPage;
