import React from "react";
import ChatFriendsWidget from "./chat/ChatFriendsWidget";
import ChatLiveWidget from "./chat/ChatLiveWidget";

const ChatWidget: React.FC<{}> = () => {
  return (
    <div className="chat coming-soon" id="user_chat">
      <ul className="nav nav-tabs" id="chat_tabs" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="live-tab"
            data-toggle="tab"
            href="#live"
            role="tab"
            aria-controls="live"
            aria-selected="false"
          >
            <i className="fal fa-comments-alt"></i>
            <span>Live Chat</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link "
            id="friends-tab"
            data-toggle="tab"
            href="#friends"
            role="tab"
            aria-controls="friends"
            aria-selected="true"
          >
            <i className="fal fa-user-friends"></i>
            <span>Friends</span>
          </a>
        </li>
      </ul>

      <div className="tab-content" id="chat_tabsContent">
        <div
          className="tab-pane fade active show"
          id="live"
          role="tabpanel"
          aria-labelledby="live-tab"
        >
          <ChatLiveWidget />
        </div>

        <div
          className="tab-pane fade "
          id="friends"
          role="tabpanel"
          aria-labelledby="friends-tab"
        >
          <ChatFriendsWidget />
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
