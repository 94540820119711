import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";
import { getUserBalance, getUserProfile, getUserXP } from '../api/ESports/userProfileService';
import AuthWidget from '../components/auth/AuthWidget';
import { ReactToastifyContainer } from '../components/common/ReactToastifyContainer';
import Aside from '../components/layout/Aside';
import { isAuthenticatedSelector } from '../store/authentication/authenticationSelector';
import { currentUserLoggedOutCreator, setLoadedUserBalanceCreator, setLoadedUserProfileCreator, setLoadedUserXPCreator } from '../store/currentUser/currentUserActionCreator';

interface IMainLayoutProps {
}

const MainLayout: React.FC<IMainLayoutProps> = (props) => {

  const navigate = useNavigate();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if(isAuthenticated){
      getUserBalance()
        .then((balance) => setLoadedUserBalanceCreator(balance))
        .catch((e) => navigate("/error"));

      getUserXP()
        .then((xp) => setLoadedUserXPCreator(xp))
        .catch((e) => navigate("/error"));

      getUserProfile()
        .then((profile) => setLoadedUserProfileCreator(profile))
        .catch((e) => navigate("/error"));
    } else {
      currentUserLoggedOutCreator();
    }
  }, [isAuthenticated]);

  return (
    <div className="two_col">
      <header>
          <div className="brand">
              <a href="#" className="nav-icon" id="nav-icon">
                  <span></span>
                  <span></span>
                  <span></span>
              </a>
              <a href="#"><img src="/images/ES-logo.svg" className="logo" alt="Esports Leagues" /></a>
          </div>
          <AuthWidget />
      </header>
      <div className="content">
        <Aside />
        <Outlet />
      </div>
      <ReactToastifyContainer />
    </div>
  );
}

export default MainLayout;
