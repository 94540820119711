export enum DialogType {
  NONE,
}

export enum ActionKeys {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
}

export interface IDialogAction {
  type: ActionKeys;
  name: DialogType;
  props: unknown;
}

export type Action = IDialogAction;
