import { DrawType, Action, ActionKeys } from './drawActions';

export interface IDrawState {
  open: boolean;
  name: DrawType;
  props: unknown;
}

const initialDrawState: IDrawState = {
  open: false,
  name: DrawType.NONE,
  props: {},
};

export function drawReducer(state: IDrawState = initialDrawState, action: Action): IDrawState {
  switch (action.type) {
    case ActionKeys.OPEN_DRAW: {
      return {
        ...state,
        open: true,
        name: action.name,
        props: action.props || {},
      };
    }
    case ActionKeys.CLOSE_DRAW: {
      return {
        ...state,
        open: false,
        name: DrawType.NONE,
        props: {},
      };
    }

    default:
      return state;
  }
}
