import { DialogType, Action, ActionKeys } from './dialogActions';

export interface IDialogState {
  open: boolean;
  name: DialogType;
  props: unknown;
}

const initialDialogState: IDialogState = {
  open: false,
  name: DialogType.NONE,
  props: {},
};

export function dialogReducer(state: IDialogState = initialDialogState, action: Action): IDialogState {
  switch (action.type) {
    case ActionKeys.OPEN_DIALOG: {
      return {
        ...state,
        open: true,
        name: action.name,
        props: action.props,
      };
    }
    case ActionKeys.CLOSE_DIALOG: {
      return {
        ...state,
        open: false,
        name: DialogType.NONE,
        props: {},
      };
    }

    default:
      return state;
  }
}
