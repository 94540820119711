import { CouponSummaryDto } from '../../types/dto/coupon/CouponSummaryDto';
import { GenericResultDto } from '../../types/dto/GenericResultDto';
import EnterLeagueRequestDto from '../../types/dto/league/EnterLeagueRequestDto';
import LeaguePredictedAnswerDto from '../../types/dto/league/LeaguePredictedAnswerDto';
import LeagueSummaryDto from '../../types/dto/league/LeagueSummaryDto';
import UpdateSelectionRequestDto from '../../types/dto/league/UpdateSelectionRequestDto';
import { TransactionResultDto } from '../../types/dto/TransactionResultDto';
import { genericProtectedGet, genericProtectedPost } from '../apiUtils';

const baseApiUrl = `${process.env.REACT_APP_API_URL as string}/League/`;

export function enterLeague(request: EnterLeagueRequestDto): Promise<TransactionResultDto<GenericResultDto<number>>> {
  const url = new URL(`${baseApiUrl}Enter`);
  return genericProtectedPost<GenericResultDto<number>, EnterLeagueRequestDto>(url.toString(), request);
}

export function updateSelection(request: UpdateSelectionRequestDto): Promise<TransactionResultDto<GenericResultDto<number>>> {
  const url = new URL(`${baseApiUrl}UpdateSelection`);
  return genericProtectedPost<GenericResultDto<number>, UpdateSelectionRequestDto>(url.toString(), request);
}

export function getCouponSummaryForLeague(
  leagueId: number
): Promise<CouponSummaryDto> {
  const url = new URL(`${baseApiUrl}${leagueId}/Coupon/Summary`);
  return genericProtectedGet<CouponSummaryDto>(url.toString());
}

export function getLeagueSummaryById(
  leagueId: number
): Promise<LeagueSummaryDto> {
  const url = new URL(`${baseApiUrl}${leagueId}/Summary`);
  return genericProtectedGet<LeagueSummaryDto>(url.toString());
}

export function getLeaguePredictedAnswersById(
  leagueId: number
): Promise<LeaguePredictedAnswerDto[]> {
  const url = new URL(`${baseApiUrl}${leagueId}/Prediction`);
  return genericProtectedGet<LeaguePredictedAnswerDto[]>(url.toString());
}