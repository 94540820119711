import React from "react";
import BalanceWidget from "../../components/widget/BalanceWidget";
import ChatWidget from "../../components/widget/ChatWidget";
import SuperLeagueWidget from "../../components/widget/SuperLeagueWidget";
import MyBets from "./components/MyBets";
import FeaturedCoupon from "../../components/widget/FeaturedCoupon";
import LeaguePageTabs from "./components/LeaguePageTabs";

const MyBetsPage: React.FC<{}> = () => {
  return (
    <main id="my-bets-page" className="three_col_inner">
      <div className="dashboard_inner">
        <LeaguePageTabs />
        <MyBets />
        <FeaturedCoupon />
      </div>
      <div className="league_sidebar sidebar">
        <BalanceWidget />
        <SuperLeagueWidget />
      </div>
      <ChatWidget />
    </main>
  );
};

export default MyBetsPage;
