import React from "react";
import { Form } from "react-bootstrap";

const Withdraw: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <main className="withdraw">
        <a className="account_back_link" id="withdraw_back">
          <i className="fal fa-angle-left"></i>Back to account
        </a>

        <h2>Withdraw funds</h2>
        <p>
          It can take up to 5 working days for funds to appear in your bank
          account.
        </p>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("Withdraw form submitted");
          }}
        >
          <div className="alert alert-info " role="alert">
            <span>
              <h2>You do not have any cards enabled against your account</h2>
              <p>
                Please click here to{" "}
                <a
                  href="/MyAccount/Manage/Cards"
                  style={{ textDecoration: "underline" }}
                  className=""
                >
                  add a card
                </a>
              </p>
            </span>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <input
                  className="form-control form-control-lg"
                  data-val="true"
                  id="DepositAmount"
                  name="DepositAmount"
                  placeholder=" "
                  step="0.01"
                  type="number"
                  value="0"
                />
                <label>Withdrawal amount *</label>
              </div>
            </div>
            <div className="col-6 details_container">
              <div className="form-group">
                <p className="details">
                  <span>Min £5</span>
                </p>
              </div>
            </div>
            <span
              className="field-validation-valid"
              data-valmsg-for="DepositAmount"
              data-valmsg-replace="true"
            ></span>
          </div>
          <div className="footer_btns">
            <button className="btn btn-primary btn-lg" type="submit">
              <i className="fa fa-lock"></i>Withdraw
            </button>
          </div>
        </Form>
      </main>
    </React.Fragment>
  );
};

export default Withdraw;
