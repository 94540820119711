import React from "react";
import CurrentUserLeagues from "../../../components/common/user/CurrentUserLeagues";
import { Link } from "react-router-dom";
import { isCurrentUrl } from "../../../utils/UrlUtil";

export interface ILeagePageTabProps {
  totalBets?: number;

  totalUpcomingLeagues?: number;
}

const LeaguePageTabs: React.FC<ILeagePageTabProps> = ({
  totalBets,
  totalUpcomingLeagues,
}: ILeagePageTabProps) => {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <Link
          className={`nav-link ${isCurrentUrl("/my-bets") ? "active" : ""}`}
          to="/my-bets"
        >
          My Bets {totalBets}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            isCurrentUrl("/upcoming-leagues") ? "active" : ""
          }`}
          to="/upcoming-leagues"
        >
          Upcoming Leagues {totalUpcomingLeagues}
        </Link>
      </li>
    </ul>
  );
};

export default LeaguePageTabs;
