import React from 'react';

const Overview: React.FC<{}> = () => {
  

  return (
    <main className="overview">
      <ul className="" id="" role="tablist">
          <li className="">
              <a href="" className="account_btns" id="deposit-button"><i className="fal fa-coins"></i><span>Deposit</span></a>
          </li>
          <li className="">
              <a href="" className="account_btns" id="withdraw-button"><i className="fal fa-coins"></i><span>Withdraw</span></a>
          </li>
          <li className="">
              <a href="" className="account_btns" id="addCard-button"><i className="fal fa-credit-card-front"></i><span>Add Card</span></a>
          </li>
          <li className="">
              <a href="" className="account_btns" id="openBets-button"><i className="fal fa-list"></i><span>Open Bets</span></a>
          </li>
          <li className="nav-item">
              <a href="" className="account_btns" id="settledBets-button"><i className="fal fa-tasks"></i><span>Settled Bets</span></a>
          </li>
          <li className="nav-item">
              <a href="" className="account_btns" id="transactions-button"><i className="fal fa-th-list"></i><span>Transactions</span></a>
          </li>
          <li className="nav-item">
              <a href="" className="account_btns" id="personal-button"><i className="fal fa-user"></i><span>Personal Details</span></a>
          </li>
      </ul>
  </main>
  );
};

export default Overview;
