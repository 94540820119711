import React from 'react';
import { Link } from 'react-router-dom';

export interface ISideMenu {
    url: string;
    label: string;
    active: boolean;
    fav: boolean;
}

interface ISideMenuProps {
    links: ISideMenu[];
    title: string;
}

const SideMenu: React.FC<ISideMenuProps> = (props) => {

    const { links, title } = props;
    return (
        <section>
            {title && <h3>{title}</h3>}
            <ul>
                {links && links.map((x) => <li className={`${x.active ? "active" : ""} ${x.url ? "" : "disabled"} ${x.fav ? "fav" : ""}`}><Link to={x.url}>{x.label}</Link></li>)}
            </ul>
        </section>
    );
};

export default SideMenu;
    