/* eslint-disable no-unused-expressions */
import * as React from "react";
import { useRef, useMemo } from "react";
import { TwitchPlayer } from "../../assets/js/twitch";

export interface ITwitchVideoPlayerProps {
  video?: string;
  id?: string;
  channel?: string;
  collection?: string;
  height?: string;
  width?: string;
  playsInline?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  allowFullscreen?: boolean;
  time?: string;
  hideControls?: boolean;
  onPlayerEnded?: () => void;
  onPlayerPause?: () => void;
  onPlayerPlay?: () => void;
  onPlayerPlaybackBlocked?: () => void;
  onPlayerPlaying?: () => void;
  onPlayerOffline?: () => void;
  onPlayerReady?: () => void;
}

const TwitchVideoPlayer: React.FC<ITwitchVideoPlayerProps> = (
  props: ITwitchVideoPlayerProps
) => {
  const {
    video,
    id,
    channel,
    collection,
    height,
    width,
    playsInline,
    autoplay,
    muted,
    allowFullscreen,
    time,
    hideControls,
    onPlayerEnded,
    onPlayerPause,
    onPlayerPlay,
    onPlayerPlaybackBlocked,
    onPlayerPlaying,
    onPlayerOffline,
    onPlayerReady,
  } = props;

  const playing = useRef<boolean>(false);

  const onEnded = () => {
    playing.current = false;
    // sendPlayerEnded(playerDetails);
    onPlayerEnded && onPlayerEnded();
  };

  const onPause = () => {
    playing.current = false;
    onPlayerPause && onPlayerPause();
  };

  const onPlay = () => {
    playing.current = true;
    onPlayerPlay && onPlayerPlay();
  };

  const onPlaybackBlocked = () => {
    playing.current = false;
    onPlayerPlaybackBlocked && onPlayerPlaybackBlocked();
  };

  const onPlaying = () => {
    playing.current = true;
    onPlayerPlaying && onPlayerPlaying();
  };

  const onOffline = () => {
    playing.current = false;
    onPlayerOffline && onPlayerOffline();
  };

  const onReady = () => {
    playing.current = false;
    onPlayerReady && onPlayerReady();
  };

  const player = useMemo(() => {
    return (
      <TwitchPlayer
        video={video}
        id={id}
        channel={channel}
        collection={collection}
        height={height}
        width={width}
        playsInline={playsInline}
        autoplay={autoplay}
        muted={muted}
        allowFullscreen={allowFullscreen}
        time={time}
        hideControls={hideControls}
        onEnded={onEnded}
        onPause={onPause}
        onPlay={onPlay}
        onPlaybackBlocked={onPlaybackBlocked}
        onPlaying={onPlaying}
        onOffline={onOffline}
        onReady={onReady}
      />
    );
  }, [
    video,
    id,
    channel,
    collection,
    height,
    width,
    playsInline,
    autoplay,
    muted,
    allowFullscreen,
    time,
    hideControls,
  ]);

  return player;
};

export default TwitchVideoPlayer;
