import React from 'react';

const ChatLiveWidget: React.FC<{}> = () => {
  return (
    <div className="live">
    <div className="header">
        <label className="sr-only">League Chats</label>
        <select className="form-control form-control-sm">
            <option>Example league name</option>
            <option>Example league name 2</option>
        </select>
    </div>
    <div className="body">
        <ul className="message_list">
            <li className="message me">
                <span className="userProfile">RM</span>
                <span className="messageBody"><span className="username">rmounty</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                <span className="time">15:43</span>            
            </li>
            <li className="message others">
                <span className="userProfile">RM</span>
                <span className="messageBody"><span className="username">rmounty</span> example comment over two lines</span>
                <span className="time">15:43</span>            
            </li>
            <li className="message others">
                <span className="userProfile">RM</span>
                <span className="messageBody"><span className="username">rmounty</span> example comment over two lines</span>
                <span className="time">15:43</span>            
            </li>
            <li className="message others">
                <span className="userProfile">RM</span>
                <span className="messageBody"><span className="username">rmounty</span> example comment over two lines</span>
                <span className="time">15:43</span>            
            </li>
            <li className="message others">
                <span className="userProfile">RM</span>
                <span className="messageBody"><span className="username">rmounty</span> example comment over two lines</span>
                <span className="time">15:43</span>            
            </li>
        </ul>
    </div>
    <div className="footer">
        <div className="form-group">
            <label className="sr-only">Send message</label>
            <input type="text" className="form-control form-control-md" placeholder="Send message"/>
            <a href="" className="emoticons"><i className="fas fa-smile"></i><span className="sr-only"></span></a>
            <a href="" className="send"><i className="fas fa-paper-plane"></i><span className="sr-only"></span></a>
        </div>
    </div>
</div>
  )
};

export default ChatLiveWidget;
