import React from 'react';
import { useSelector } from 'react-redux';
import CurrentUserXP from '../../../components/common/user/CurrentUserXP';
import { currentUserFirstNameSelector } from '../../../store/currentUser/currentUserSelector';

const Header: React.FC<{}> = () => {

  const firstName = useSelector(currentUserFirstNameSelector);

  return (
    <div className="dashboard_header">
        <div className="intro">
            {firstName && <h1>Welcome back, <span>{firstName}</span></h1>}
            <h4>LAST LOGGED IN: YESTERDAY -  14:28</h4>
        </div>
        <CurrentUserXP />
    </div>
  )
};

export default Header;
