export enum DrawType {
  NONE,
}

export enum ActionKeys {
  OPEN_DRAW = 'OPEN_DRAW',
  CLOSE_DRAW = 'CLOSE_DRAW',
}

export interface IDrawAction {
  type: ActionKeys;
  name: DrawType;
  props: unknown;
}

export type Action = IDrawAction;
