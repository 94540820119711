import React from "react";
import { Form } from "react-bootstrap";

const Details: React.FC<{}> = () => {
  return (
    <main className="add-card">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Details form submitted");
        }}
      >
        <section>
          <h2>Personal Details</h2>
          <p>
            Please fill in the form below to securely add a card to your POOL250
            account.
          </p>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <input
                  className="form-control form-control-lg"
                  data-val="true"
                  id="Name"
                  name="Name"
                  placeholder=" "
                  type="text"
                  value=""
                />
                <label htmlFor="Name" className="">
                  First name: <span>*</span>
                </label>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="Name"
                  data-valmsg-replace="true"
                ></span>
                <span
                  className="field-validation-valid-check"
                  style={{ display: "none" }}
                ></span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  className="form-control form-control-lg"
                  data-val="true"
                  id="Surname"
                  name="Surname"
                  placeholder=" "
                  type="text"
                  value=""
                />
                <label htmlFor="Surname" className="">
                  Surname: <span>*</span>
                </label>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="Surname"
                  data-valmsg-replace="true"
                ></span>
                <span className="field-validation-valid-check"></span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              className="form-control form-control-lg"
              data-val="true"
              data-val-required="The Email field is required."
              id="Email"
              name="Email"
              placeholder=" "
              type="text"
              value=""
            />
            <label htmlFor="Email" className="">
              Email address: <span>*</span>
            </label>
            <span
              className="field-validation-valid"
              data-valmsg-for="Email"
              data-valmsg-replace="true"
            ></span>
            <span className="field-validation-valid-check"></span>
          </div>
          <div className="form-group">
            <input
              className="form-control form-control-lg"
              data-val="true"
              data-val-regex="Please enter a valid telephone number"
              id="TelNo"
              name="TelNo"
              placeholder=" "
              type="text"
              value=""
            />
            <label htmlFor="TelNo" className="">
              Telephone number: <span>*</span>
            </label>
            <span
              className="field-validation-valid"
              data-valmsg-for="TelNo"
              data-valmsg-replace="true"
            ></span>
            <span className="field-validation-valid-check"></span>
          </div>
          <div className="footer_btns">
            <button className="btn btn-primary btn-lg" type="submit">
              Update Details
            </button>
          </div>
        </section>
        <section>
          <h2>Security Information</h2>
          <p>
            If you cannot remember your password, please click here to reset
            your password
          </p>

          <div className="form-group">
            <input
              className="form-control form-control-lg"
              id="Password"
              name="Password"
              placeholder=" "
              type="password"
            />
            <label htmlFor="Password">Existing Password</label>
            <span
              className="field-validation-valid"
              data-valmsg-for="Password"
              data-valmsg-replace="true"
            ></span>
          </div>

          <div className="form-group password_strength">
            <input
              className="form-control form-control-lg"
              id="Password"
              name="Password"
              placeholder=" "
              type="password"
            />
            <label htmlFor="Password">Create New Password</label>
            <span className="badge badge-danger">Too short</span>
            <span className="hint">
              <p>
                Your password must have a minimum of: 1 uppercase character, 1
                lowercase character, 1 numeric value, 1 symbol
              </p>
            </span>
            <span
              className="field-validation-valid"
              data-valmsg-for="Password"
              data-valmsg-replace="true"
            ></span>
          </div>

          <div className="form-group">
            <input
              className="form-control form-control-lg"
              id="Password"
              name="Password"
              placeholder=" "
              type="password"
            />
            <label htmlFor="Password">Confirm New Password</label>
            <span
              className="field-validation-valid"
              data-valmsg-for="Password"
              data-valmsg-replace="true"
            ></span>
          </div>
          <div className="footer_btns">
            <button className="btn btn-primary btn-lg" type="submit">
              Update Password
            </button>
          </div>
        </section>
        <section>
          <h2>Marketing Preferences</h2>
          <p>
            If you would prefer not to receive marketing from Pool250, please
            disable any one of the marketing channels below:
          </p>
          <div className="form-group">
            <div className="checkbox">
              <input data-val="true" id="Terms" name="Terms" type="checkbox" />
              <label htmlFor="Terms">
                Email <span>*</span>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="Terms"
                  data-valmsg-replace="true"
                ></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <input data-val="true" id="Terms" name="Terms" type="checkbox" />
              <label htmlFor="Terms">
                SMS <span>*</span>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="Terms"
                  data-valmsg-replace="true"
                ></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <input data-val="true" id="Terms" name="Terms" type="checkbox" />
              <label htmlFor="Terms">
                Push notifications <span>*</span>
                <span
                  className="field-validation-valid"
                  data-valmsg-for="Terms"
                  data-valmsg-replace="true"
                ></span>
              </label>
            </div>
          </div>
          <div className="footer_btns">
            <button className="btn btn-primary btn-lg" type="submit">
              Update Preferences
            </button>
          </div>
        </section>
      </Form>
    </main>
  );
};

export default Details;
