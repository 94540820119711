import { UserBalanceDto } from '../../types/dto/userProfile/UserBalanceDto';
import { UserProfileDto } from '../../types/dto/userProfile/UserProfileDto';
import { UserXPDto } from '../../types/dto/userProfile/UserXPDto';
import { Action, ActionKeys, ILoadedUserBalanceAction, ILoadedUserProfileAction, ILoadedUserXPAction } from './currentUserActions';

export interface ICurrentUserState {
  loadingBalance: boolean;
  balance?: UserBalanceDto;
  loadingXP: boolean;
  XP?: UserXPDto;
  loadingUserProfile: boolean;
  profile?: UserProfileDto;
}

const initialCurrentUserState: ICurrentUserState = {
  loadingBalance: true,
  loadingUserProfile: true,
  loadingXP: true
};

export function currentUserReducer(state: ICurrentUserState = initialCurrentUserState, action: Action): ICurrentUserState {
  switch (action.type) {
    case ActionKeys.USER_LOADED: {
      return {
        ...state
      };
    }
    case ActionKeys.USER_LOADING_USER_BALANCE: {
      return {
        ...state,
        loadingBalance: true
      };
    }
    case ActionKeys.USER_LOADED_USER_BALANCE: {
      return {
        ...state,
        loadingBalance: false,
        balance: (action as ILoadedUserBalanceAction).balance
      };
    }
    case ActionKeys.USER_LOADING_USER_XP: {
      return {
        ...state,
        loadingXP: true
      };
    }
    case ActionKeys.USER_LOADED_USER_XP: {
      return {
        ...state,
        loadingXP: false,
        XP: (action as ILoadedUserXPAction).balance
      };
    }
    case ActionKeys.USER_LOADING_USER_PROFILE: {
      return {
        ...state,
        loadingUserProfile: true
      };
    }
    case ActionKeys.USER_LOADED_USER_PROFILE: {
      return {
        ...state,
        loadingBalance: false,
        profile: (action as ILoadedUserProfileAction).profile
      };
    }
    case ActionKeys.USER_LOGGED_OUT: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
}
