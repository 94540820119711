enum StatusEnum {
  Open = 1,
  CreatingLeague = 2,
  Delayed = 3,
  Running = 4,
  Finished = 5,
  Abandoned = 6,
  Cancelled = 7,
  PartialResult = 8
}

export default StatusEnum;
