import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getCouponFixtures } from "../../api/ESports/couponService";
import { getStatsForFixtureCoupon } from "../../api/ESports/fixtureService";
import {
  getCurrentUserLeagues,
  getLeagueEntryPositions,
} from "../../api/ESports/leagueEntryService";
import {
  getCouponSummaryForLeague,
  getLeaguePredictedAnswersById,
  getLeagueSummaryById,
} from "../../api/ESports/leagueService";
import CountdownRenderer from "../../components/common/CountdownRenderer";
import TwitchVideoPlayer from "../../components/common/TwitchVideoPlayer";
import ChatWidget from "../../components/widget/ChatWidget";
import { CouponSummaryDto } from "../../types/dto/coupon/CouponSummaryDto";
import { CouponFixtureSummaryDto } from "../../types/dto/couponFixture/CouponFixtureSummaryDto";
import FixtureStatsDto from "../../types/dto/fixture/FixtureStatsDto";
import LeagueSummaryDto from "../../types/dto/league/LeagueSummaryDto";
import LeagueEntryPositionDto from "../../types/dto/leagueEntry/LeagueEntryPositionDto";
import LeagueEntrySummaryDto from "../../types/dto/leagueEntry/LeagueEntrySummaryDto";
import LeagueEntryStatusEnum from "../../types/enums/LeagueEntryStatus";
import StatusEnum from "../../types/enums/StatusEnum";
import { dateRangeText, tableDateString } from "../../utils/DateUtil";
import { formatCurrency, ordinalSuffixOf } from "../../utils/NumberUtil";
import { getParameterByName } from "../../utils/StringUtil";
import LeagueEntryAnswers from "./components/LeagueEntryAnswers";
import TeamVs from "../../components/common/team/TeamVs";
import LeaguePredictedAnswerDto from "../../types/dto/league/LeaguePredictedAnswerDto";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const LeagueDetailPage: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [couponSummary, setCouponSummary] = useState<CouponSummaryDto>(
    {} as unknown as CouponSummaryDto
  );
  const [leagueSummary, setLeagueSummary] = useState<LeagueSummaryDto>(
    {} as unknown as LeagueSummaryDto
  );
  const [couponFixtures, setCouponFixtures] = useState<
    CouponFixtureSummaryDto[]
  >([]);
  const [leagueEntries, setLeagueEntries] = useState<LeagueEntrySummaryDto[]>(
    []
  );
  const [leaguePredictedAnswers, setLeaguePredictedAnswers] = useState<
    LeaguePredictedAnswerDto[]
  >([]);
  const [leagueTables, setLeagueTableEntries] = useState<
    LeagueEntryPositionDto[]
  >([]);
  const [selectedLeagueEntry, setSelectedLeagueEntry] = useState<number>();
  const [fixtureStats, setFixtureStats] = useState<FixtureStatsDto>({
    fixtureId: 0,
    homeTeamScore: 0,
    awayTeamScore: 0,
    stats: [],
  });
  const [activeLeagueEntry, setActiveLeagueEntry] = useState<number>(0);
  const [hideBestslips, setHideBetslips] = useState<boolean>(true);

  const refreshCurrentUserLeagues = () => {
    getCurrentUserLeagues(
      LeagueEntryStatusEnum.None,
      undefined,
      undefined,
      Number(id),
      0,
      100
    ).then((result) => {
      getLeagueEntryPositions(
        Number(id),
        result.model.map((x) => x.leagueEntryId)
      ).then((le) => {
        setLeagueEntries(result.model);
        setLeagueTableEntries(le);
      });
    });
  };

  const refreshFixtures = (couponId: number) => {
    getCouponFixtures(couponId).then((f) => {
      setCouponFixtures(f);
    });
  };

  const refreshStats = (fixture: CouponFixtureSummaryDto) => {
    if (!couponSummary || !couponSummary.couponId || !fixture) return;

    getStatsForFixtureCoupon(fixture.fixtureId, couponSummary.couponId).then(
      (result) => setFixtureStats(result)
    );
  };

  useEffect(() => {
    setLoading(true);

    var interval: string | number | NodeJS.Timeout | undefined;
    var fixtureInterval: string | number | NodeJS.Timeout | undefined;

    Promise.all([
      getCouponSummaryForLeague(Number(id)),
      getLeagueSummaryById(Number(id)),
      getLeaguePredictedAnswersById(Number(id)),
    ])
      .then((result) => {
        setCouponSummary(result[0]);
        setLeagueSummary(result[1]);
        setLeaguePredictedAnswers(result[2]);
        setLoading(false);
        getCouponFixtures(result[0].couponId).then((f) => {
          setCouponFixtures(f);
        });
        refreshCurrentUserLeagues();
        var hasEnded = result[0] && new Date(result[0].endDate) < new Date();

        if (!hasEnded) {
          interval = setInterval(refreshCurrentUserLeagues, 30000);
          fixtureInterval = setInterval(
            () => refreshFixtures(result[0].couponId),
            30000
          );
        }
      })
      .catch(() => {
        navigate("/error");
      });

    return () => {
      clearInterval(interval);
      clearInterval(fixtureInterval);
    };
  }, [id]);

  const dates = dateRangeText(
    couponSummary?.startDate ? new Date(couponSummary.startDate) : undefined,
    couponSummary?.endDate ? new Date(couponSummary.endDate) : undefined
  );

  let currentFixture: CouponFixtureSummaryDto;

  if (
    couponFixtures.filter((x) => x.statusId === StatusEnum.Running).length > 0
  ) {
    currentFixture = couponFixtures.filter(
      (x) => x.statusId === StatusEnum.Running
    )[0];
  } else if (
    couponFixtures.filter((x) => x.statusId === StatusEnum.Open).length > 0
  ) {
    currentFixture = couponFixtures
      .filter((x) => x.statusId === StatusEnum.Open)
      .sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      )[0];
  } else {
    currentFixture = couponFixtures
      .filter((x) => x.statusId === StatusEnum.Open)
      .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())[
      couponFixtures.length - 1
    ];
  }

  useEffect(() => {
    var interval: string | number | NodeJS.Timeout | undefined;
    refreshStats(currentFixture);
    interval = setInterval(() => {
      refreshStats(currentFixture);
      getCouponSummaryForLeague(Number(id)).then((cs) => setCouponSummary(cs));
    }, 30000);

    return () => clearInterval(interval);
  }, [currentFixture]);

  const twitchCh =
    currentFixture &&
    currentFixture.streamUrl &&
    currentFixture.streamUrl.includes("twitch") &&
    currentFixture.streamUrl.includes("channel")
      ? getParameterByName("channel", currentFixture.streamUrl)
      : undefined;

  const today = new Date();

  const currentFixtureStarted =
    currentFixture && new Date(currentFixture.time) < today;

  const couponLive =
    couponSummary && new Date(couponSummary.entryCloseDate) < today;

  const couponFinished =
    couponSummary && new Date(couponSummary.endDate) < today;

  const renderLeagueEntryRow = (row: LeagueEntryPositionDto, i: number) => {
    return (
      <React.Fragment>
        {i > 0 &&
          leagueTables[i - 1].position !== row.position &&
          leagueTables[i - 1].position !== row.position - 1 && (
            <div>
              <i className="far fa-ellipsis-h"></i>
            </div>
          )}
        <div
          className={`league row ${
            leagueEntries.filter((z) => z.leagueEntryId === row.leagueEntryId)
              .length > 0
              ? "active"
              : ""
          }`}
          key={i}
        >
          <div className="col-1 position">
            {row.prevPosition && row.position < row.prevPosition && (
              <i className="far fa-chevron-up"></i>
            )}
            {row.prevPosition && row.position > row.prevPosition && (
              <i className="far fa-chevron-down"></i>
            )}
            {` ${row.position}`}
          </div>
          <div className="col-3 user">
            <div className="avatar">
              {row.userFirstName && row.userFirstName[0]}
              {row.userSurname && row.userSurname[0]}
            </div>
            <div className="username">{row.username}</div>
          </div>
          <div className="col-2 winnings">
            {row.prize && (
              <div className="winning">{formatCurrency(row.prize)}</div>
            )}
            {!row.prize && <React.Fragment>-</React.Fragment>}
          </div>
          <div className="col-2 points">
            {row.points ?? 0}
            <span>pts</span>
          </div>
          <div className="col-2 points">
            {row.bonusPoints ?? 0}
            <span>pts</span>
          </div>
          <div className="col-1 points">
            {row.points + row.bonusPoints}
            <span>pts</span>
          </div>
          {/* <div className="col-1 cta">
                <i className="far fa-ellipsis-h" onClick={() => setSelectedLeagueEntry(row.leagueEntryId)}></i>
              </div> */}
          {/* {selectedLeagueEntry && selectedLeagueEntry === row.leagueEntryId && (
                <div className="col-12">
                  <LeagueEntryAnswers leagueEntryId={selectedLeagueEntry} fixtures={couponFixtures} />
                </div>
              )} */}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <main id="league-details-page" className="three_col_inner">
        <div className="league-details">
          {loading && <LoadingSpinner />}
          {!couponFinished && (
            <div className="league-video">
              <div
                className={`video-wrapper ${
                  twitchCh && currentFixture && currentFixtureStarted
                    ? "p-0"
                    : "p-4"
                }`}
                style={{flex: 1}}
              >
                {!loading && !currentFixture && (
                  <div className="league-detail-header">
                    <h4>{couponSummary.couponName}</h4>
                    <div className="event-name ml-4">
                      {couponSummary?.productName}
                    </div>
                  </div>
                )}
                {currentFixture && !currentFixtureStarted && (
                  <div className="coupon-fixtures">
                    {couponFixtures.map((f) => (
                      <div key={f.fixtureId} className="league-detail-fixture">
                        <TeamVs
                          homeTeamName={f.homeTeamName}
                          homeTeamImageUrl={f.homeTeamImageUrl}
                          awayTeamName={f.awayTeamName}
                          awayTeamImageUrl={f.awayTeamImageUrl}
                        />
                        <h3>{tableDateString(new Date(f.time))}</h3>
                        <div className="countdown">
                          <h4>Game Starts In</h4>
                          <Countdown
                            date={f.time}
                            renderer={CountdownRenderer}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {!twitchCh && currentFixture && currentFixtureStarted && (
                  <div className="no-stream">
                    No stream available for this fixture
                  </div>
                )}
                {twitchCh && currentFixture && currentFixtureStarted && (
                  <TwitchVideoPlayer
                    key={twitchCh}
                    channel={twitchCh}
                    width="100%"
                    height="500px"
                  />
                )}
              </div>
              <div className="stats">
                <div className="title">
                  {couponSummary && !couponLive ? (
                    <div className="countdown">
                      <h4>Entry closes</h4>
                      <Countdown
                        zeroPadDays={2}
                        zeroPadTime={2}
                        date={new Date(couponSummary.entryCloseDate)}
                        renderer={CountdownRenderer}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {currentFixture &&
                        (currentFixture.statusId === StatusEnum.Running ||
                          currentFixture.statusId === StatusEnum.Finished) && (
                          <div className="live">
                            <div className="live-block">
                              {currentFixture?.statusName}
                            </div>
                          </div>
                        )}
                      {currentFixture &&
                        currentFixtureStarted &&
                        currentFixture.statusId !== StatusEnum.Running && (
                          <div className="live">
                            <div className="live-block">Pending</div>
                          </div>
                        )}
                      {currentFixture && currentFixtureStarted && (
                        <TeamVs
                          homeTeamName={currentFixture.homeTeamName}
                          homeTeamImageUrl={currentFixture.homeTeamImageUrl}
                          awayTeamName={currentFixture.awayTeamName}
                          awayTeamImageUrl={currentFixture.awayTeamImageUrl}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div className="stat-container">
                  {couponSummary && couponLive ? (
                    fixtureStats.stats.map((stat, i) => {
                      return (
                        <div className="stat" key={i}>
                          <h4>{stat.question}</h4>
                          {stat.values.map((v, ii) => (
                            <div key={ii}>
                              {stat.questionTypeID === 1 &&
                                v.participantName && (
                                  <div className="number">
                                    {v.participantName}
                                  </div>
                                )}
                              {stat.questionTypeID === 2 && v.resultValue && (
                                <div className="number">{v.resultValue}</div>
                              )}
                              {stat.questionTypeID === 4 && v.team && (
                                <div className="number">{v.team}</div>
                              )}
                              {stat.questionTypeID === 1 && (
                                <div className="logo">
                                  <img
                                    src={
                                      v.participantProfileImageURL ??
                                      "/images/participant-holding.png"
                                    }
                                    alt={v.participantName}
                                  />
                                </div>
                              )}
                              {stat.questionTypeID === 4 && (
                                <div className="logo">
                                  <img
                                    src={
                                      v.teamProfileLogoUrl ??
                                      "/images/team-holding.png"
                                    }
                                    alt={v.team}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      );
                    })
                  ) : (
                    <React.Fragment>
                      <h4>Predicted winners</h4>
                      {!!leaguePredictedAnswers?.length &&
                        leaguePredictedAnswers
                          // .filter((la) => la.questionTypeId === 4)
                          // TODO: Revert to above once questionTypeId is coming back correctly
                          .filter(
                            (la) => la.question === "Who will be the winner?"
                          )
                          .map((la, idx) => (
                            <div className="stat-fixture">
                              <TeamVs
                                homeTeamName={`${Math.floor(
                                  la.homeWinPercentage
                                )}%`}
                                homeTeamImageUrl={la.homeTeamProfileLogoURL}
                                awayTeamName={`${Math.ceil(
                                  la.awayWinPercentage
                                )}%`}
                                awayTeamImageUrl={la.awayTeamProfileLogoURL}
                              />
                              <div
                                className={`progress ${
                                  idx % 2 === 0 ? "even" : "odd"
                                } ${
                                  la.awayWinPercentage > la.homeWinPercentage
                                    ? "reverse"
                                    : ""
                                }`}
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: `${68}%` }}
                                  role="progressbar"
                                ></div>
                              </div>
                            </div>
                          ))}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="league-detail-wrapper">
          <div className="league-main">
            <div className="league-header">
              <h2>League Standings</h2>
              <a href="#">How scoring works</a>
            </div>
            <div className="leagues_list">
              <div className="league_list header row">
                <div className="col-1 position">Pos</div>
                <div className="col-3">User</div>
                <div className="col-2">Winnings (£)</div>
                <div className="col-2">Points</div>
                <div className="col-2">Bonus Points</div>
                <div className="col-2">Total</div>
                {/* <div className="col-1"></div> */}
              </div>
              <div className="league-table">
                {leagueTables &&
                  leagueTables.length > 0 &&
                  leagueTables.map(renderLeagueEntryRow)}
              </div>
            </div>
          </div>
          {/* <div className="league-sidebar sidebar">
            <a href="" className="league_crossSell">
              <span>XP / Callenges / Expert Predictions</span>
            </a>
            <a href="" className="league_crossSell">
              <span>Cross sell advert - Upcoming leagues</span>
            </a>
          </div> */}
        </div>
        {leagueEntries && leagueEntries.length > 0 && (
          <div className="myBetslip">
            {/* <div className="showHide" id="showBetslip">
                {hideBestslips && <div className="show visible" onClick={() => setHideBetslips(false)}>Show betslip<i className="far fa-chevron-up"></i></div>}
                {!hideBestslips && <div className="show visible"  onClick={() => setHideBetslips(true)}>Hide betslip<i className="far fa-chevron-down"></i></div>}
              </div> */}
            <ul
              className="nav nav-tabs myBetslip-tabs"
              id="myTab"
              role="tablist"
            >
              {leagueEntries.map((leagueEntry, i) => (
                <li
                  className="nav-item"
                  role="presentation"
                  key={leagueEntry.leagueEntryId}
                >
                  <button
                    className={`nav-link ${
                      activeLeagueEntry === i ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveLeagueEntry(i);
                      setHideBetslips(false);
                    }}
                  >
                    Betslip {i + 1}{" "}
                    {leagueEntry.betName && `(${leagueEntry.betName})`}{" "}
                    <span>{`${ordinalSuffixOf(leagueEntry.position)}`}</span>
                  </button>
                </li>
              ))}
            </ul>
            <div
              className="tab-content myBetslip-tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show active"
                id="betslip1"
                role="tabpanel"
                aria-labelledby="betslip1-tab"
              >
                <div>
                  <div
                    className="leagues_list single"
                    onClick={() =>
                      setSelectedLeagueEntry(
                        leagueEntries[activeLeagueEntry].leagueEntryId
                      )
                    }
                  >
                    {renderLeagueEntryRow(
                      leagueTables.filter(
                        (l) =>
                          l.leagueEntryId ===
                          leagueEntries[activeLeagueEntry].leagueEntryId
                      )[0],
                      0
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <div className="betslip">
        {selectedLeagueEntry && (
          <LeagueEntryAnswers
            leagueEntryId={selectedLeagueEntry}
            fixtures={couponFixtures}
            closeMyBet={() => setSelectedLeagueEntry(undefined)}
          />
        )}
        {!selectedLeagueEntry && <ChatWidget />}
      </div>
    </React.Fragment>
  );
};

export default LeagueDetailPage;
