import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ResponseSetDto } from "../../../types/dto/ResponseSetDto";
import LeagueEntryStatusEnum from "../../../types/enums/LeagueEntryStatus";
import { getCurrentUserLeagues } from "../../../api/ESports/leagueEntryService";
import LeagueEntryTimeFilterEnum from "../../../types/enums/LeagueEntryTimeFilterEnum";
import LeagueEntrySummaryDto from "../../../types/dto/leagueEntry/LeagueEntrySummaryDto";
import LoadingSpinner from "../../../components/common/LoadingSpinner";

interface ISearchLeaguesParams {
  status: LeagueEntryStatusEnum;
  timeFilter: LeagueEntryTimeFilterEnum;
  page: number;
  limit: number;
}

const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const MyBets: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<
    ResponseSetDto<LeagueEntrySummaryDto>
  >({
    totalCount: 0,
    count: 0,
    model: [],
  });
  const [searchResultsGrouped, setSearchResultsGrouped] = useState<any>({});
  const [searchParams, setSearchParams] = useState<ISearchLeaguesParams>({
    status: LeagueEntryStatusEnum.Open,
    timeFilter: LeagueEntryTimeFilterEnum.Today,
    page: 0,
    limit: 100,
  });

  const loadLeagues = (cancelled = false) => {
    setLoading(true);

    var from = new Date();
    var to = new Date();

    if (searchParams.status === LeagueEntryStatusEnum.Open) {
      if (searchParams.timeFilter === LeagueEntryTimeFilterEnum.Future) {
        to = "" as unknown as Date;
        from.setDate(from.getDate() + 1);
      }
    } else if (searchParams.status === LeagueEntryStatusEnum.Settled) {
      from = "" as unknown as Date;
    }

    getCurrentUserLeagues(
      searchParams.status,
      from,
      to,
      0,
      searchParams.page,
      searchParams.limit
    )
      .then((result) => {
        if (cancelled) return;

        setSearchResultsGrouped(groupBy(result.model, "leagueId"));
        setSearchResults(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    var cancelled = false;

    loadLeagues(cancelled);

    return () => {
      cancelled = true;
    };
  }, [
    searchParams.limit,
    searchParams.page,
    searchParams.status,
    searchParams.timeFilter,
  ]);

  var resultKeys = Object.keys(searchResultsGrouped);

  return (
    <React.Fragment>
      <div className="dashboard_leagues">
        <div className="leagues_header">
          <div className="filters">
            <div className="btn_group">
              <div>
                <input
                  type="radio"
                  checked={searchParams.status === LeagueEntryStatusEnum.Open}
                  id="open_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      status: LeagueEntryStatusEnum.Open,
                    })
                  }
                />
                <label htmlFor="open_bet-type">Open</label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.status === LeagueEntryStatusEnum.Settled
                  }
                  id="settled_bet-type"
                  name="bet-type"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      status: LeagueEntryStatusEnum.Settled,
                    })
                  }
                />
                <label htmlFor="settled_bet-type">Settled</label>
              </div>
            </div>
            <div className="btn_group">
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.timeFilter === LeagueEntryTimeFilterEnum.Today
                  }
                  id="today_coupon-date"
                  name="coupon-date"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      timeFilter: LeagueEntryTimeFilterEnum.Today,
                    })
                  }
                />
                <label htmlFor="today_coupon-date">Today</label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={
                    searchParams.timeFilter === LeagueEntryTimeFilterEnum.Future
                  }
                  id="yest_coupon-date"
                  name="coupon-date"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      timeFilter: LeagueEntryTimeFilterEnum.Future,
                    })
                  }
                />
                <label htmlFor="yest_coupon-date">Tomorrow</label>
              </div>
            </div>
          </div>
        </div>
        <div className="leagues_list">
          {loading && <LoadingSpinner />}
          {!loading &&
            searchResults.model.map((l) => {
              return (
                <div className="league" key={l.couponId}>
                  <div className="league_icon">
                    <img src="images/league-mark.svg" alt="Premier League" />
                  </div>
                  <div className="league-split">
                    <div className="upper">
                      <div className="league_titles">
                        <h4>{l.sportName}</h4>
                        <h3>{l.name}</h3>
                      </div>
                      <div className="league_details">
                        <div className="value">£5 league</div>
                        <div className="entries">150 entries</div>
                      </div>
                      <div className="league-buttons">
                        <Link to="/manage-account/settled-bets" className="">
                          <span className="sr-only">View prizes</span>
                          <i className="fal fa-pound-sign"></i>
                        </Link>
                        <Link to={`/enter-coupon/${l.couponId}`}>
                          <span className="sr-only">Edit league</span>
                          <i className="fal fa-pencil"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="lower">
                      <div className="position">{l.position}</div>
                    </div>
                  </div>
                  <div className="league_actions">
                    <Link
                      to={`/enter-coupon/${l.couponId}/${l.leagueEntryId}`}
                      className="link"
                    >
                      <span className="sr-only">View League</span>
                      <i className="fal fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyBets;
