import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { authenticateUser } from "../../api/ESports/authenticationService";
import {
  createGenericErrorToast,
  createValidationErrorToast,
} from "../../components/common/ReactToastifyContainer";
import { userAuthenticatedCreator } from "../../store/authentication/authenticationActionCreator";
import { isAuthenticatedSelector } from "../../store/authentication/authenticationSelector";
import { setFieldErrorsFromTransactionErrors } from "../../utils/FormUtil";
import LoadingSpinner from "../../components/common/LoadingSpinner";

interface ILoginForm {
  username: string;
  password: string;
}

const LoginPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [processing, setProcessing] = useState<boolean>();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<ILoginForm>();

  const username = watch("username");
  const password = watch("password");

  const isLoginAvailable = useMemo(
    () => !!username && !!password,
    [username, password]
  );

  const onSubmit = handleSubmit((data: ILoginForm) => {
    setProcessing(true);

    authenticateUser(data)
      .then((result) => {
        if (result.success && result?.model?.token) {
          userAuthenticatedCreator(
            result.model.token,
            result?.model?.refreshToken ?? ""
          );
          return;
        }
        createValidationErrorToast();
        setFieldErrorsFromTransactionErrors<ILoginForm>(
          result?.errors ?? {},
          setError
        );
        setProcessing(false);
      })
      .catch((e) => {
        createGenericErrorToast();
        setProcessing(false);
      });
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <div id="login-page">
      {processing ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={onSubmit}>
          <h1 className="hidden-xs hidden-sm">Login to your account</h1>
          <div className="">
            <div className="form-group">
              <input
                {...register("username")}
                className="form-control form-control-lg"
                placeholder="EMAIL ADDRESS"
              />
              {errors?.username && (
                <span className="field-validation-valid">
                  {errors.username.message}
                </span>
              )}
            </div>
            <div className="form-group">
              <input
                {...register("password")}
                className="form-control form-control-lg"
                type="password"
                placeholder="PASSWORD"
              />
              {errors?.password && (
                <span className="field-validation-valid">
                  {errors.password.message}
                </span>
              )}
            </div>
          </div>
          <div className="btn-container">
            <a href="/error" className="text-link">
              Forgot Password?
            </a>
            <input
              disabled={!isLoginAvailable}
              type="submit"
              className="btn btn-lg btn-primary"
              value="Login"
            />
          </div>
          <span className="crossSell">
            <p>
              Don’t have an account? <a href="/error">Create an account</a>
            </p>
          </span>
        </form>
      )}
    </div>
  );
};

export default LoginPage;
