/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux';
import { IAppState, IOidcState } from '../store/rootReducer';
import { TransactionResultDto } from '../types/dto/TransactionResultDto';
import { store } from '..';
import { ErrorResponseDto } from '../types/dto/network/ErrorResponseDto';

export function getHeaderWithAuthorization(): Headers {

  const state = store.getState();

  return new Headers({
    Accept: 'application/json',
    'content-type': 'application/json',
    Authorization: `Bearer ${state.authenticationState.token}`
  });
}

export function getHeader(): Headers {

  return new Headers({
    Accept: 'application/json',
    'content-type': 'application/json'
  });
}

export function getHeaders(token: string): Array<Record<string, unknown>> {
  return [{ Accept: 'application/json' }, { 'content-type': 'application/json' }, { Authorization: `Bearer ${token}` }];
}

export function getHeaderWithAuthorizationSync(): Array<Record<string, unknown>> {
  const oidcState: IOidcState = useSelector<IAppState, IOidcState>((state: IAppState) => state.oidcState);

  return [
    { Accept: 'application/json' },
    { 'content-type': 'application/json' },
    { Authorization: `Bearer ${oidcState.user.access_token}` },
  ];
}

export function genericGet<TResult>(url: string): Promise<TResult> {
  const headers = getHeader();
    return fetch(url, {
      headers,
    })
      .then((response: Response) => {
        if (response.status === 404) {
          // NotFound was returned from C# so handle null object in your calling function
          return null;
        }
        const result = response.json();
        return result;
      })
      .then((data: TResult) => {
        return data;
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericProtectedGet<TResult>(url: string): Promise<TResult> {
  const headers = getHeaderWithAuthorization();
    return fetch(url, {
      headers,
    })
      .then((response: Response) => {
        if (response.status === 404) {
          // NotFound was returned from C# so handle null object in your calling function
          return null;
        }

        return response.text().then((text) => {
          return text ? JSON.parse(text) : null;
        });
      })
      .then((data: TResult) => {
        return data;
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericPost<TResult, TPostData>(url: string, data: TPostData): Promise<TransactionResultDto<TResult>> {
  const headers = getHeader();
  
    const tResult = new TransactionResultDto<TResult>();
    const content = JSON.stringify(data);
    return fetch(url, {
      headers,
      method: 'POST',
      body: content,
    })
      .then((response) => {
        // 400 (bad request) here
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        // handle empty response
        return response.text().then((text) => {
          tResult.success = true;
          tResult.model = text ? JSON.parse(text) : null;
          return tResult;
        });
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericProtectedPost<TResult, TPostData>(url: string, data: TPostData): Promise<TransactionResultDto<TResult>> {
  const headers = getHeaderWithAuthorization();
    const tResult = new TransactionResultDto<TResult>();
    const content = JSON.stringify(data);
    return fetch(url, {
      headers,
      method: 'POST',
      body: content,
    })
      .then((response) => {
        // 400 (bad request) here
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        // handle empty response
        return response.text().then((text) => {
          if(text) return JSON.parse(text);
          
          tResult.success = true;
          return tResult;
        });
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericPut<TPostData>(url: string, data: TPostData): Promise<TransactionResultDto<TPostData>> {
  const headers = getHeader();

    const tResult = new TransactionResultDto<TPostData>();
    const content = JSON.stringify(data);
    return fetch(url, {
      headers,
      method: 'PUT',
      body: content,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        tResult.success = true;
        tResult.model = data;
        return tResult;
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericProtectedPut<TPostData>(url: string, data: TPostData): Promise<TransactionResultDto<TPostData>> {
  const headers = getHeaderWithAuthorization();

    const tResult = new TransactionResultDto<TPostData>();
    const content = JSON.stringify(data);
    return fetch(url, {
      headers,
      method: 'PUT',
      body: content,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        tResult.success = true;
        tResult.model = data;
        return tResult;
      })
      .catch((err: unknown) => {
        throw err;
      });
}

export function genericDelete(url: string): Promise<TransactionResultDto<boolean>> {
  const headers = getHeader();

    const tResult = new TransactionResultDto<boolean>();
    return fetch(url, {
      headers,
      method: 'Delete',
    })
      .then((response) => {
        // 400 (bad request) here
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        tResult.success = true;
        return tResult;
      })
      .catch((err: unknown) => {
        // 500 (internal server error) here
        throw err;
      });
}

export function genericProtectedDelete(url: string): Promise<TransactionResultDto<boolean>> {
  const headers = getHeaderWithAuthorization();

    const tResult = new TransactionResultDto<boolean>();
    return fetch(url, {
      headers,
      method: 'Delete',
    })
      .then((response) => {
        // 400 (bad request) here
        if (response.status === 400) {
          return response.json().then((r: ErrorResponseDto) => {
            tResult.success = false;
            tResult.errors = r.errors;
            return tResult;
          });
        }
        tResult.success = true;
        return tResult;
      })
      .catch((err: unknown) => {
        // 500 (internal server error) here
        throw err;
      });
}

// export function downloadFile(url: string, filename: string) {
//   const headers = getHeaderWithAuthorization();

//     return fetch(url, {
//       headers,
//     })
//       .then((response) => response.blob())
//       .then((blobby) => {
//         const objectUrl = window.URL.createObjectURL(blobby);
//         const anchor = document.createElement('a');
//         anchor.href = objectUrl;
//         anchor.download = filename;
//         anchor.click();

//         window.URL.revokeObjectURL(objectUrl);
//       })
//       .catch(() => createErrorToast('Unable to download file, please try again.'));
// }

export function getSource(url: string, onLoad: (file: unknown) => void) {
  const headers = getHeaderWithAuthorization();

    return fetch(url, {
      headers,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        // eslint-disable-next-line func-names
        reader.onload = function () {
          onLoad(this.result);
        };
        reader.readAsDataURL(blob);
      });
}

// export async function handleResponse(response: any) {
//   if (response.ok) {
//     return response.json();
//   }
//   if (response.status === 400) {
//     // So, a server-side validation error occurred.
//     // Server side validation returns a string error message, so parse as text instead of json.
//     const error = await response.text();
//     throw new Error(error);
//   }
//   throw new Error('Network response was not ok.');
// }

// // In a real app, would likely call an error logging service.
// export function handleError(error: any) {
//   throw error;
// }
