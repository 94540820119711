import React from "react";
import { CountdownRenderProps } from "react-countdown";

const CountdownRenderer: React.FC<CountdownRenderProps> = ({
  completed,
  formatted: { days, hours, minutes, seconds },
}) => {
  if (completed) {
    // Render a completed state
    return <React.Fragment>-</React.Fragment>;
  } else {
    const parts = [days, hours, minutes, seconds];
    return (
      <div className="countdown_block">
        {parts.filter(Boolean).map((x, i) => (
          <span className="days">
            {i > 0 && ":"}
            <span>{x}</span>
          </span>
        ))}
      </div>
    );
  }
};

export default CountdownRenderer;
