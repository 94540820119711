import React from "react";

const ActivityLogWidget: React.FC<{}> = () => {
  return (
    <div className="activity-log aside-panel">
      <div className="header">
        <div className="icon">
          <i className="fas fa-list"></i>
        </div>
        <h3>Activity feed</h3>
      </div>
      <div className="body coming-soon">
        <ul>
          <li>
            <div className="activity-icon">
              <i className="fas fa-trophy"></i>
            </div>
            <div className="item">
              <h4>Abduz just won £25.00 in the V10 Silver League</h4>
              <p className="time-ago">2 minutes ago</p>
            </div>
          </li>
          <li>
            <div className="activity-icon">
              <i className="fas fa-trophy"></i>
            </div>
            <div className="item">
              <h4>Abduz just won £25.00 in the V10 Silver League</h4>
              <p className="time-ago">2 minutes ago</p>
            </div>
          </li>
          <li>
            <div className="activity-icon">
              <i className="fas fa-trophy"></i>
            </div>
            <div className="item">
              <h4>Abduz just won £25.00 in the V10 Silver League</h4>
              <p className="time-ago">2 minutes ago</p>
            </div>
          </li>
          <li>
            <div className="activity-icon">
              <i className="fas fa-trophy"></i>
            </div>
            <div className="item">
              <h4>Abduz just won £25.00 in the V10 Silver League</h4>
              <p className="time-ago">2 minutes ago</p>
            </div>
          </li>
          <li>
            <div className="activity-icon">
              <i className="fas fa-trophy"></i>
            </div>
            <div className="item">
              <h4>Abduz just won £25.00 in the V10 Silver League</h4>
              <p className="time-ago">2 minutes ago</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ActivityLogWidget;
