import React from "react";
import SidebarPayments from "./SidebarPayments";
import { Form } from "react-bootstrap";

const AddCard: React.FC<{}> = () => {
  return (
    <main className="add-card">
      <a className="account_back_link" id="withdraw_back">
        <i className="fal fa-angle-left"></i>Back to account
      </a>

      <h2>Add a card</h2>
      <p>
        Please fill in the form below to securely add a card to your POOL250
        account.
      </p>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Add card form submitted");
        }}
      >
        <div className="form-group">
          <input
            className="form-control form-control-lg"
            data-val="true"
            data-val-required="The Email field is required."
            id="Email"
            name="Email"
            placeholder=" "
            type="text"
            value=""
          />
          <label htmlFor="Email" className="">
            Cardholder Name: <span>*</span>
          </label>
          <span
            className="field-validation-valid"
            data-valmsg-for="Email"
            data-valmsg-replace="true"
          ></span>
          <span className="field-validation-valid-check"></span>
        </div>
        <div className="form-group">
          <input
            className="form-control form-control-lg"
            data-val="true"
            data-val-regex="Please enter a valid telephone number"
            id="TelNo"
            name="TelNo"
            placeholder=" "
            type="text"
            value=""
          />
          <label htmlFor="TelNo" className="">
            Card number: <span>*</span>
          </label>
          <span
            className="field-validation-valid"
            data-valmsg-for="TelNo"
            data-valmsg-replace="true"
          ></span>
          <span className="field-validation-valid-check"></span>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-3 col-md-2">
              <input
                className="form-control form-control-lg"
                id="DOB_Month"
                name="DOB_Month"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Month" className="">
                CVV
              </label>
            </div>
            <div className="col-9 col-md-10 details_container">
              <p>Last 3 digitals on the reverse of the card</p>
            </div>
          </div>
          <span
            className="field-validation-valid"
            data-valmsg-for="TelNo"
            data-valmsg-replace="true"
          ></span>
          <span className="field-validation-valid-check"></span>
        </div>

        <div className="form-group">
          <label htmlFor="Expiry" className="top_label">
            Expiry date: <span>*</span>
          </label>
          <div className="row">
            <div className="col-3 col-md-2">
              <input
                className="form-control form-control-lg"
                id="DOB_Month"
                name="DOB_Month"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Month" className="">
                MM
              </label>
            </div>
            <div className="col-4 col-md-3">
              <input
                className="form-control form-control-lg"
                id="DOB_Year"
                name="DOB_Year"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Year" className="">
                YYYY
              </label>
            </div>
          </div>
          <span
            className="field-validation-valid"
            data-valmsg-for="TelNo"
            data-valmsg-replace="true"
          ></span>
          <span className="field-validation-valid-check"></span>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-7 col-md-5">
              <input
                className="form-control form-control-lg"
                id="DOB_Month"
                name="DOB_Month"
                placeholder=" "
                type="text"
                value=""
              />
              <label htmlFor="DOB_Month" className="">
                Deposit Amount: <span>*</span>
              </label>
            </div>
            <div className="col-5 col-md-5 details_container">
              <p>Min £5</p>
            </div>
          </div>
          <span
            className="field-validation-valid"
            data-valmsg-for="TelNo"
            data-valmsg-replace="true"
          ></span>
          <span className="field-validation-valid-check"></span>
        </div>
        <div className="footer_btns">
          <button type="submit" className="btn btn-primary btn-lg">
            <i className="fa fa-lock" />
            Withdraw
          </button>
        </div>
      </Form>
    </main>
  );
};

export default AddCard;
