import { Route, Routes } from "react-router";
import GuardedRoute from "./components/auth/GuardedRoute";
import LoginPage from "./feature/authentication/LoginPage";
import EnterCouponPage from "./feature/coupon/EnterCouponPage";
import DashboardPage from "./feature/dashboard/DashboardPage";
import LeagueDetailPage from "./feature/league/LeagueDetailPage";
import MainLayout from "./layouts/MainLayout";
import MinLayout from "./layouts/MinLayout";
import { ErrorPage } from "./staticPages/ErrorPage";
import { PageNotFound } from "./staticPages/PageNotFound";
import MyBetsPage from "./feature/league/MyBetsPage";
import UpcomingLeaguesPage from "./feature/league/LeaguePage";
import "./assets/scss/app.scss";
import AccountPage from "./feature/account/AccountPage";
import useScreenSize from "./utils/useScreenSize";

function App() {
  const { width } = useScreenSize();

  // For Beta, any UIs on small screen sizes are disabled.
  if (width < 900) {
    return (
      <div id="screen-disabled" className="two_col">
        <div className="card">
          <h2>
            Unfortunately the current Beta release is not available on smaller
            devices and screen sizes
          </h2>
          <p>
            We will be sending regular updates to our Beta adopters as we roll
            out new features and enhancements to the platform
          </p>
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/dashboard/*" element={<GuardedRoute />}>
          <Route path="" element={<DashboardPage />} />
        </Route>
        <Route path={"/my-bets"} element={<GuardedRoute />}>
          <Route path="" element={<MyBetsPage />} />
        </Route>
        <Route path={"/manage-account/:tab"} element={<GuardedRoute />}>
          <Route path="" element={<AccountPage />} />
        </Route>
        <Route path={"/upcoming-leagues"} element={<GuardedRoute />}>
          <Route path="" element={<UpcomingLeaguesPage />} />
        </Route>
        <Route path={"/league/:id/*"} element={<GuardedRoute />}>
          <Route path="" element={<LeagueDetailPage />} />
        </Route>
        <Route path={"/enter-coupon/:id/:entryId/*"} element={<GuardedRoute />}>
          <Route path="*" element={<EnterCouponPage />} />
        </Route>
        <Route path={"/enter-coupon/:id/*"} element={<GuardedRoute />}>
          <Route path="*" element={<EnterCouponPage />} />
        </Route>
      </Route>
      <Route element={<MinLayout />}>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
