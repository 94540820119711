import React from 'react';
import SidebarPayments from './components/SidebarPayments';
import Overview from './components/Overview';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import AddCard from './components/AddCard';
import OpenBets from './components/OpenBets';
import SettledBets from './components/SettledBets';
import Transactions from './components/Transactions';
import Details from './components/Details';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const AccountPage: React.FC<{}> = () => {
  
  const {tab} = useParams();

  return (
    <main className="two_col">
      <div className="myAccount_Container">
          <h1>My Account</h1>
          <div className="tab_container">
              <ul className="nav nav-tabs" id="account_tabs" role="tablist">
                  <li className="nav-item">
                      <Link to="/manage-account/deposit" className={`nav-link ${tab == "deposit" ? "active" : ""}`} id="deposit-tab" data-toggle="tab" role="tab" aria-controls="deposit" aria-selected="false">Deposit</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "withdraw" ? "active" : ""}`} id="withdraw-tab" data-toggle="tab"  to="/manage-account/withdraw" role="tab" aria-controls="withdraw" aria-selected="true">Withdraw</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "add-card" ? "active" : ""}`} id="add-card-tab" data-toggle="tab" to="/manage-account/add-card" role="tab" aria-controls="add-card" aria-selected="false">Add Card</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "open-bets" ? "active" : ""}`} id="open-bet-tab" data-toggle="tab" to="/manage-account/open-bets" role="tab" aria-controls="open-bet" aria-selected="false">Open Bets</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "settled-bets" ? "active" : ""}`} id="settled-bet-tab" data-toggle="tab" to="/manage-account/settled-bets" role="tab" aria-controls="settled-bet" aria-selected="false">Settled Bets</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "transactions" ? "active" : ""}`} id="transaction-tab" data-toggle="tab" to="/manage-account/transactions" role="tab" aria-controls="transaction" aria-selected="false">Transactions</Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${tab == "personal-details" ? "active" : ""}`} id="details-tab" data-toggle="tab" to="/manage-account/personal-details" role="tab" aria-controls="details" aria-selected="false">Personal Details</Link>
                  </li>
              </ul>

              <div className="tab-content" id="account_tabsContent">
                  <div className="" id="account_buttons" >
                      <Overview />
                  </div>
                  <div className={`tab-pane fade ${tab == "deposit" ? "active show" : ""}`} id="deposit" role="tabpanel" aria-labelledby="deposit-tab">
                      <Deposit />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>

                  <div className={`tab-pane fade ${tab == "withdraw" ? "active show" : ""}`} id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                      <Withdraw />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>

                  <div className={`tab-pane fade ${tab == "add-card" ? "active show" : ""}`} id="add-card" role="tabpanel" aria-labelledby="add-card-tab">
                      <AddCard />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>
                  <div className={`tab-pane fade ${tab == "open-bets" ? "active show" : ""}`} id="open-bet" role="tabpanel" aria-labelledby="open-bet-tab">
                      <OpenBets />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>

                  <div className={`tab-pane fade ${tab == "settled-bets" ? "active show" : ""}`} id="settled-bet" role="tabpanel" aria-labelledby="settled-bet-tab">
                      <SettledBets />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>
                  <div className={`tab-pane fade ${tab == "transactions" ? "active show" : ""}`} id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                      <Transactions />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>
                  <div className={`tab-pane fade ${tab == "personal-details" ? "active show" : ""}`} id="details" role="tabpanel" aria-labelledby="details-tab">
                      <Details />
                      <aside>
                        <SidebarPayments />
                      </aside>
                  </div>
              </div>
          </div>
      </div>
  </main>
  );
};

export default AccountPage;
