import { ActionKeys } from './authenticationActions';
import { store } from '../../index';

export const userAuthenticatedCreator = (token: string, refreshToken: string): void => {
  store.dispatch({
    type: ActionKeys.USER_AUTHENTICATED,
    token: token,
    refreshToken: refreshToken,
  });
};

export const logoutCreator = (): void => {
  store.dispatch({
    type: ActionKeys.LOGOUT,
  });
};
